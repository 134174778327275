import { Directive, ElementRef, HostListener } from '@angular/core';
import { parsePhoneNumber } from 'libphonenumber-js';

@Directive({ selector: '[appPhoneNumberFormat]' })
export class PhoneNumberFormatDirective {
  constructor(private el: ElementRef) {
  }

  @HostListener('keypress', ['$event']) onKeypress(e) {
    const element = this.el.nativeElement;
    const elementValue = element.value;
    const parsedPhone = parsePhoneNumber(elementValue, 'US');
    element.value = parsedPhone.formatInternational();
  }
}
