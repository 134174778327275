export const environment = {
  name: 'production',
  production: true,
  api_uri: 'https://heyniceplants.com/api',
  app_version: '2.0.4',
  btClientToken: 'production_5r4mvgrg_fw4bbg4skkshkwwh',
  gtmContainerId: 'GTM-K7VNN4R',
  gtmEnvironment: '&gtm_auth=7s_SJnlv66-qmPgVh4EkMg&gtm_preview=env-1&gtm_cookies_win=x',
  propsToClearOnLS: {
    selectedPlanId: true,
    checkoutUser: true
  },
  pca_predict: {
    key: 'ZW39-HX97-ZC55-NM99',
    api_endpoint: ''
  },
  bugSnagKey: '2cf8b7001ca9d97bac061ea3717d4cee'
};
