<div class="lead-banner">
  <a class="close" (click)="closeLeadForm()">
    <span class="sr-only">close</span>
  </a>
  <div class="lead-banner-content">
    <h1 class="lead-banner__title text-bolder">$5 OFF</h1>

    <div class="lead-banner__sub-content">
      <h3 class="lead-banner__subtitle">YOUR FIRST MONTH</h3>
      <!-- [formGroup]="parent" -->
      <form class="lead-banner__form" [formGroup]="form">
        <div class="form-group">
          <input
            class="lead-input form-group__input text-extralight"
            id="nameInput" type="email"
            placeholder="Enter your email"
            formControlName="email"
            required="required"/>
          <span class="lead-error error-text"
                *ngIf="isFieldInvalid('email', 'invalidEmail')">Please use a valid email</span>

          <span *ngIf="(showSuggestedEmail$ | async)" class="user-suggested-email">
            Do you mean <strong (click)="saveSuggestedEmail()">{{ suggestedEmail$ | async }}</strong>?
          </span>
        </div>
        <button
          class="lead-banner__btn btn btn-primary"
          type="button"
          (click)="submitLeadForm()">I want plants
        </button>
      </form>


    </div>
    <a [attr.test-id]="'lead-banner__close-button'" class="lead-banner__link"
        (click)="closeLeadForm()">No thanks, I don’t want a $5 discount.
    </a>
  </div>
</div>
