import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import * as jwt_decode from 'jwt-decode';
import * as isAfter from 'date-fns/is_after';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { APP_CONFIG, AppConfig } from '@app/app.config';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class AuthService {

  constructor(
    @Inject(APP_CONFIG) private app_config: AppConfig,
    private http: HttpClient,
    private localStorage: LocalStorageService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,
  ) { }

  get tokenPayload() {
    let tokenDecoded;
    const token = this.localStorage.retrieve('authToken');
    if (token) {
      tokenDecoded = jwt_decode(token);
      tokenDecoded._id = tokenDecoded._id || tokenDecoded.id;
    }
    return tokenDecoded;
  }

  get token() {
    const token = this.localStorage.retrieve('authToken');
    if (token) {
      return token;
    }
    return null;
  }

  get isTokenExpired() {
    if (this.tokenPayload) {
      return isAfter(new Date(), this.tokenPayload.exp);
    }
    return true;
  }

  createUser(data): Observable<HttpEvent<any>> {
    const request = new HttpRequest(
      'POST', `${this.app_config.api_uri}/users`, data, { reportProgress: true }
    );

    return this.http.request(request)
      .pipe(map((e: HttpEvent<any>) => this.interceptAuthorizationHeader(e)));
  }

  loginUser({ email, password }): Observable<any> {
    const request = new HttpRequest(
      'POST', `${this.app_config.api_uri}/users/login`,
      { email, password },
      { reportProgress: true },
    );

    return this.http
      .request(request)
      .pipe(map((e: HttpEvent<any>) => this.interceptAuthorizationHeader(e)));
  }

  checkUserExist(email: string): Observable<HttpEvent<any>> {
    const request = new HttpRequest(
      'POST',
      `${this.app_config.api_uri}/users/check_email`,
      { email },
      { reportProgress: true }
    );

    return this.http
      .request(request);
  }

  checkToken(token: string): Observable<string> {
    return this.http
      .post(`${this.app_config.api_uri}/users/checkToken`, { token })
      .pipe(map((data: any) => data.token));
  }

  refreshToken(token: string) {
    return this.http
      .post(`${this.app_config.api_uri}/users/token`, {}, { observe: 'response' })
      .pipe(map((e: HttpEvent<any>) => this.interceptAuthorizationHeader(e)));
  }

  profile() {
    // const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    const userId = this.tokenPayload._id || this.tokenPayload.id;
    return this.http
      .post(`${this.app_config.api_uri}/users/${userId}/profile`, {})
      .pipe(map((e: HttpEvent<any>) => this.interceptAuthorizationHeader(e)));
  }

  forgotPassword(email: string) {
    return this.http
      .post(`${this.app_config.api_uri}/users/forgot-password`, { email });
  }

  recoveryPassword(token: string, password: string, confirmPassword: string): Observable<any> {
    return this.http
      .post(`${this.app_config.api_uri}/users/recover-password`, { token, password, confirmPassword });
  }

  logout() {
    this.localStorage.clear('authToken');
    this.router.navigate(['/']);
  }

  setJWTToken(token: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.localStorage.store('authToken', token)
    }
  }

  unsetJWTToken() {
    this.localStorage.clear('authToken');
  }

  removeJWTToken() {
    this.localStorage.clear('authToken');
  }

  interceptAuthorizationHeader(event: HttpEvent<any>) {
    if (event.type === HttpEventType.Response) {
      const token = event.headers.get('authorization').split(' ')[1];
      this.setJWTToken(token);
    }
    return event;
  }
}
