import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// rxjs
import {BehaviorSubject, interval, Observable, of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';

// config
import { APP_CONFIG, AppConfig } from './../../app.config';
import { Coupon } from '../models/coupon.model';

// selectors
import { CouponsService } from './coupons.service';
import {isPlatformBrowser} from '@angular/common';

@Injectable()
export class AppService {
  userIp: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  timeInSite: BehaviorSubject<number> = new BehaviorSubject(0);

  appLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  coupon$: Observable<Coupon>;

  constructor(
    private httpClient: HttpClient,
    private couponsService: CouponsService,
    @Inject(APP_CONFIG) private app_config: AppConfig,
    @Inject(PLATFORM_ID) private platform: any,
  ) {  }

  init() {
    this.couponsService.init();
    if (isPlatformBrowser(this.platform)) {
      this.getIP();
      this.startTimerTimeInSite();
    }
  }

  getUUID() {
    const headers = new HttpHeaders({ retry: 'yes' });
    return this.httpClient
      .get(`${this.app_config.api_uri}/client-uuid`, { headers })
      .pipe(map((response: any) => response.uuid));
  }

  setAppLoading(state: boolean): void {
    this.appLoading$.next(state);
  }

  getIP(): void {
    this.httpClient.get<{ ip: string }>('https://cors-anywhere.herokuapp.com/http://api.ipify.org/?format=json')
      .subscribe(response => {
        if (!!response) {
          this.userIp.next(response.ip);
        }
      }, error => {})
  }

  startTimerTimeInSite(): void {
    interval(1000)
      .pipe(map(value => value + 1))
      .subscribe(value => this.timeInSite.next(value));
  }

  scrollToTop() {
    if (isPlatformBrowser(PLATFORM_ID)) {
      window.scroll(0, 0);
    }
  }

  initIntercom() {
    // initialize intercom
    if (isPlatformBrowser(PLATFORM_ID) && (window as any).Intercom) {
      (window as any).Intercom('boot', {
        app_id: 'kdi5b3f0'
      });
    }
  }

  updateIntercom() {
    if (isPlatformBrowser(PLATFORM_ID) && (window as any).Intercom) {
      (window as any).Intercom('update');
    }
  }
}
