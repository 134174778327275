import { Injectable, NgZone, Inject, PLATFORM_ID } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

export interface Entites<T> {
  [id: string]: T
}

export interface Variant {
  id: string,
  name: string,
}

export interface Experiment {
  id: string,
  name: string,
  variants: Entites<Variant>,
}

@Injectable()
export class GoogleOptimizeService {

  private _isGoogleOptimizeInitialized$ = new BehaviorSubject(false);
  isGoogleOptimizeInitialized$ = this._isGoogleOptimizeInitialized$.asObservable().pipe(distinctUntilChanged());

  private _experiments$ = new BehaviorSubject<Entites<Experiment>>({});
  experiments$ = this._experiments$.asObservable().pipe(distinctUntilChanged());


  constructor(private zone: NgZone, @Inject(PLATFORM_ID) private platformId: any) { }

  setGoogleOptimizeAsInitialzed() {
    this._isGoogleOptimizeInitialized$.next(true);
  }

  setExperiment(experiment: Experiment) {
    const value = this._experiments$.getValue();
    this._experiments$.next({ ...value, [experiment.id]: experiment });
  }

  setGoExperiments() {
    if (isPlatformBrowser(this.platformId)) {
      window['setGOExperiments'] = {
        zone: this.zone,
        componentFn: (experiment: Experiment) => {
          this.setGoogleOptimizeAsInitialzed();
          this.setExperiment(experiment);
          // RUN THIS FUNCTION ON OPTIMIZE OR IN YOUR CONSOLE TO ACTIVE the METHOD ABOVE
          /*
              window['setGOExperiments'].zone.run(() => {
                  window['setGOExperiments'].componentFn({ name: 'ccr_version', value: true, version: 1, reload: true })
              });
          */
        },
        component: this,
      };
    }
  }

  optimizeActivate() {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        if ((window as any).dataLayer && typeof (window as any).dataLayer.push === 'function') {
          (window as any).dataLayer.push({
            event: 'optimize.activate',
            eventCallback: (containerId, ...args) => {
              console.log('Container ID: ' + containerId, args);
            }
          });
        }
      }, 0);
    }
  }

}
