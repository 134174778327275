import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import * as jwt_decode from 'jwt-decode';
import * as isAfter from 'date-fns/is_after';
import { map } from 'rxjs/operators';
import { AppConfig } from '@app/app.config';
import { isPlatformBrowser } from '@angular/common';
var AuthService = /** @class */ (function () {
    function AuthService(app_config, http, localStorage, router, platformId) {
        this.app_config = app_config;
        this.http = http;
        this.localStorage = localStorage;
        this.router = router;
        this.platformId = platformId;
    }
    Object.defineProperty(AuthService.prototype, "tokenPayload", {
        get: function () {
            var tokenDecoded;
            var token = this.localStorage.retrieve('authToken');
            if (token) {
                tokenDecoded = jwt_decode(token);
                tokenDecoded._id = tokenDecoded._id || tokenDecoded.id;
            }
            return tokenDecoded;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "token", {
        get: function () {
            var token = this.localStorage.retrieve('authToken');
            if (token) {
                return token;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "isTokenExpired", {
        get: function () {
            if (this.tokenPayload) {
                return isAfter(new Date(), this.tokenPayload.exp);
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.createUser = function (data) {
        var _this = this;
        var request = new HttpRequest('POST', this.app_config.api_uri + "/users", data, { reportProgress: true });
        return this.http.request(request)
            .pipe(map(function (e) { return _this.interceptAuthorizationHeader(e); }));
    };
    AuthService.prototype.loginUser = function (_a) {
        var _this = this;
        var email = _a.email, password = _a.password;
        var request = new HttpRequest('POST', this.app_config.api_uri + "/users/login", { email: email, password: password }, { reportProgress: true });
        return this.http
            .request(request)
            .pipe(map(function (e) { return _this.interceptAuthorizationHeader(e); }));
    };
    AuthService.prototype.checkUserExist = function (email) {
        var request = new HttpRequest('POST', this.app_config.api_uri + "/users/check_email", { email: email }, { reportProgress: true });
        return this.http
            .request(request);
    };
    AuthService.prototype.checkToken = function (token) {
        return this.http
            .post(this.app_config.api_uri + "/users/checkToken", { token: token })
            .pipe(map(function (data) { return data.token; }));
    };
    AuthService.prototype.refreshToken = function (token) {
        var _this = this;
        return this.http
            .post(this.app_config.api_uri + "/users/token", {}, { observe: 'response' })
            .pipe(map(function (e) { return _this.interceptAuthorizationHeader(e); }));
    };
    AuthService.prototype.profile = function () {
        var _this = this;
        // const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
        var userId = this.tokenPayload._id || this.tokenPayload.id;
        return this.http
            .post(this.app_config.api_uri + "/users/" + userId + "/profile", {})
            .pipe(map(function (e) { return _this.interceptAuthorizationHeader(e); }));
    };
    AuthService.prototype.forgotPassword = function (email) {
        return this.http
            .post(this.app_config.api_uri + "/users/forgot-password", { email: email });
    };
    AuthService.prototype.recoveryPassword = function (token, password, confirmPassword) {
        return this.http
            .post(this.app_config.api_uri + "/users/recover-password", { token: token, password: password, confirmPassword: confirmPassword });
    };
    AuthService.prototype.logout = function () {
        this.localStorage.clear('authToken');
        this.router.navigate(['/']);
    };
    AuthService.prototype.setJWTToken = function (token) {
        if (isPlatformBrowser(this.platformId)) {
            this.localStorage.store('authToken', token);
        }
    };
    AuthService.prototype.unsetJWTToken = function () {
        this.localStorage.clear('authToken');
    };
    AuthService.prototype.removeJWTToken = function () {
        this.localStorage.clear('authToken');
    };
    AuthService.prototype.interceptAuthorizationHeader = function (event) {
        if (event.type === HttpEventType.Response) {
            var token = event.headers.get('authorization').split(' ')[1];
            this.setJWTToken(token);
        }
        return event;
    };
    return AuthService;
}());
export { AuthService };
