import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber } from 'libphonenumber-js';

@Pipe({ name: 'formatPhoneNumber' })
export class FormatPhoneNumberPipe implements PipeTransform {
  transform(value, extension: boolean = false) {
    const parsedPhone = parsePhoneNumber(value, 'US');
    return parsedPhone.formatInternational();
  }
}
