import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConvertionService } from '@app/core/services/convertion.service';

@Component({
  selector: 'sucstu-monthly-thanks',
  templateUrl: './monthly-thanks.component.html',
  styleUrls: ['./monthly-thanks.component.scss']
})
export class MonthlyThanksComponent implements OnInit {


  data: any = this.route.snapshot.data.data;

  constructor(
    private route: ActivatedRoute,
    private convertionService: ConvertionService
  ) {
  }

  ngOnInit() {
    const total = this.data.subscription.plan.price + (
      this.data.subscription.plan.shippingCosts * this.data.subscription.plan.shipmentsAtCheckout
    );
    this.convertionService.reportMonthlyRenewal(this.data, total);
  }

}
