import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from '@app/app.config';
import * as BrandData from '@config/brand.json';
import * as GiftDoneData from '@config/gift-done.json';
import { Store } from '@ngrx/store';
// services
import { SessionStorageService } from 'ngx-webstorage';
import { map } from 'rxjs/operators';
import { PlansService } from '@app/core/services';
import * as fromCouponsActions from './../../../store/actions/coupon.actions';
import { HttpParams } from '@angular/common/http';
import { ConvertionService } from '@app/core/services/convertion.service';
var GiftDoneComponent = /** @class */ (function () {
    function GiftDoneComponent(app_config, route, plansService, sessionStorage, store, router, convertionService) {
        this.app_config = app_config;
        this.route = route;
        this.plansService = plansService;
        this.sessionStorage = sessionStorage;
        this.store = store;
        this.router = router;
        this.convertionService = convertionService;
        this.giftDoneData = GiftDoneData.default;
        this.brandData = BrandData.default;
        this.data = this.route.snapshot.data.data;
        this.renderBradsDealsPixel = false;
        this.bradsDealsTrafficData = null;
        this.bradsDealsPixelTagUrl = '';
    }
    Object.defineProperty(GiftDoneComponent.prototype, "isPlanABradsDealsDeal", {
        get: function () {
            var subscription = this.data.subscription;
            return subscription.plan._id === this.plansService.BRADS_GIFT_BOGO;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GiftDoneComponent.prototype, "isProd", {
        get: function () {
            return this.app_config.production && this.app_config.name === 'production';
        },
        enumerable: true,
        configurable: true
    });
    GiftDoneComponent.prototype.ngOnInit = function () {
        var _this = this;
        var coupon = this.data.subscription.usedCoupons[0];
        var total = this.data.subscription.plan.price + (this.data.subscription.plan.shippingCosts * this.data.subscription.plan.shipmentsAtCheckout);
        if (this.isPlanABradsDealsDeal) {
            this.plansService.getPlansStatistics()
                .pipe(map(function (plans) { return plans.find(function (plan) { return plan._id === _this.plansService.BRADS_GIFT_BOGO; }); }), map(function (plan) { return plan.total; }))
                .subscribe(function (num) {
                if (num > 500) {
                    _this.renderBradsDealsPixel = false;
                    return;
                }
                _this.renderBradsDealsPixel = true;
                var data = _this.parseBradsDealsTrafficData();
                if (!data) {
                    _this.renderBradsDealsPixel = false;
                    return;
                }
                var params = new HttpParams({
                    fromObject: {
                        nid: '104',
                        oid: data.orderId,
                        transaction_id: data.transactionId,
                        amount: total.toString().replace('.', ',')
                    }
                });
                _this.bradsDealsPixelTagUrl = "https://bradsdeals.servtrk.com/?" + params.toString();
            });
        }
        this.convertionService.reportGiftDone(this.data, total, coupon);
    };
    GiftDoneComponent.prototype.parseBradsDealsTrafficData = function () {
        var data = this.sessionStorage.retrieve('bradsDealsTrafficData');
        if (data) {
            return JSON.parse(data);
        }
        return null;
    };
    GiftDoneComponent.prototype.goToCheckoutWithCoupon = function () {
        this.store.dispatch(new fromCouponsActions.VerifyCouponAction('fivedollars'));
        this.router.navigate(['/checkout'], { queryParams: { planId: '5997c3ac8852a761249342e9' } });
    };
    return GiftDoneComponent;
}());
export { GiftDoneComponent };
