// import {
//   trigger,
//   state,
//   style,
//   animate,
//   transition
// } from '@angular/animations';
import * as tslib_1 from "tslib";
import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, ElementRef, OnInit } from '@angular/core';
import { environment } from '@env/environment';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { select, Store } from '@ngrx/store';
// RxJS
import { combineLatest, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
// services
import { AuthService } from '@app/auth/services/auth.service';
import { AppService, ConfigService, GoogleOptimizeService, LeadsService, CouponsService } from '@app/core/services';
import { ErrorService } from './error/services';
// actions
import * as userActions from '@app/store/actions/user.actions';
import * as couponsActions from '@app/store/actions/coupon.actions';
import * as notificationBarActions from '@app/store/actions/notification-bar.actions';
// selectors
import * as fromRoot from '@app/store/selectors';
import { paramsMap } from '@app/core/mappers/params-map.mapper';
import { isPlatformBrowser, Location } from '@angular/common';
var AppComponent = /** @class */ (function () {
    function AppComponent(router, route, appService, authService, leadService, errorService, store, couponService, configService, localStorage, sessionStorage, googleOptimizeService, platformId, location) {
        this.router = router;
        this.route = route;
        this.appService = appService;
        this.authService = authService;
        this.leadService = leadService;
        this.errorService = errorService;
        this.store = store;
        this.couponService = couponService;
        this.configService = configService;
        this.localStorage = localStorage;
        this.sessionStorage = sessionStorage;
        this.googleOptimizeService = googleOptimizeService;
        this.platformId = platformId;
        this.location = location;
        this.isBrowser = isPlatformBrowser(this.platformId);
        this.appLoading$ = this.appService.appLoading$.asObservable();
        this.state = 'inactive';
        this.notificationBarHeight = 0;
        this.displayMotherBanner = false;
        this.couponService.init();
        this.coupon$ = this.couponService.coupon$;
    }
    AppComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.user$ = this.store.pipe(select(fromRoot.getUserState));
        this.notificationBar$ = this.store.pipe(select(fromRoot.getNotificationBarState));
        this.sessionCoupon = JSON.parse(this.sessionStorage.retrieve('coupon'));
        this.showCouponBanner = JSON.parse(this.localStorage.retrieve('showCouponBanner'));
        this.configService.getConfig().subscribe(function (data) {
            _this.sessionStorage.store('config', data.config);
        });
        this.appService.init();
        // Clear LS props when needed.
        var currentAppVersion = this.localStorage.retrieve('appVersion');
        if (!currentAppVersion || currentAppVersion !== environment.app_version) {
            this.localStorage.store('appVersion', environment.app_version);
            Object.keys(environment.propsToClearOnLS)
                .filter(function (key) { return environment.propsToClearOnLS[key]; })
                .forEach(function (key) { return _this.localStorage.clear(key); });
        }
        // this.googleOptimizeService.setGoExperiments();
        // Set UUID in order to be capable
        // of recognize the client when the lead
        // data is saved/updated
        var availableAffiliateRefs = ['clickref', 'clickRef'];
        var availableLeadsProps = [
            'utm_medium',
            'utm_term',
            'utm_campaign',
            'utm_source',
            'utm_content',
            'http_referrer_url',
            'source'
        ];
        var queryParams$ = this.route.queryParams
            .pipe(filter(function (data) { return !!Object.keys(data).length; }), map(paramsMap));
        var utmData$ = queryParams$.pipe(filter(function (data) {
            var value = Object.keys(data)
                .findIndex(function (key) { return availableLeadsProps.includes(key); });
            return value !== -1;
        }));
        var affiliateRef$ = queryParams$.pipe(map(function (data) {
            var key = Object.keys(data).find(function (item) { return availableAffiliateRefs.includes(item); });
            return data[key] || null;
        }), filter(function (data) { return !!data; })).subscribe(function (data) {
            _this.sessionStorage.store('affiliateRef', data);
        });
        var storedUUID = this.localStorage.retrieve('uuid');
        var getUUID$ = storedUUID ? of(storedUUID) :
            this.appService.getUUID().pipe(tap(function (uuid) { return _this.localStorage.store('uuid', uuid); }));
        getUUID$
            .pipe(take(1), switchMap(function (uuid) {
            return utmData$.pipe(map(function (utmData) { return ({ uuid: uuid, params: utmData }); }));
        }), switchMap(function (_a) {
            var uuid = _a.uuid, params = _a.params;
            console.log('Here is', uuid, params);
            _this.sessionStorage.store('utmData', tslib_1.__assign({}, params, { utm_source: params.utm_source || params.source }));
            return _this.leadService.create({ uuid: uuid, utmData: params });
        })).subscribe(function (data) { return console.log(data); }, function (error) {
            var message = "Error trying to create UUID/Lead.";
            _this.errorService.client.notify(error, {
                beforeSend: function (report) {
                    report.severity = 'warning';
                    report.updateMetaData('extras', {
                        http: true,
                        client: true,
                        message: message,
                        error: error,
                    });
                }
            });
        });
        combineLatest([
            this.user$,
            this.route.queryParams.pipe(map(paramsMap), filter(function (queryParams) { return Object.keys(queryParams).length > 0 === _this.location.path().includes('?'); }), map(function (queryParams) { return queryParams.token; }))
        ])
            .pipe(switchMap(function (_a) {
            var user = _a[0], token = _a[1];
            if (token && token.length && _this.authService.token !== token) {
                _this.authService.setJWTToken(token);
                return _this.authService.checkToken(token).pipe(switchMap(function () { return of([user, token]); }), catchError(function (error) {
                    _this.authService.unsetJWTToken();
                    _this.router.navigate(['/login']);
                    return of([user, null]);
                }));
            }
            return of([user, token]);
        }))
            .subscribe(function (_a) {
            var user = _a[0], token = _a[1];
            if (token && token.length && _this.authService.token !== token
                || !user.isAuthenticated && _this.authService.token) {
                _this.store.dispatch(new userActions.LoadAction());
            }
        });
        this.user$
            .subscribe(function (user) {
            if (!user.isAuthenticated && _this.authService.token) {
                _this.store.dispatch(new userActions.LoadAction());
            }
        });
        this.coupon$
            .subscribe(function (coupon) {
            if (coupon) {
                _this.localStorage.store('showCouponBanner', true);
                _this.sessionStorage.store('coupon', JSON.stringify(coupon));
                _this.showCouponBanner = true;
                return;
            }
            _this.localStorage.store('showCouponBanner', false);
            _this.showCouponBanner = false;
        });
        this.localStorage
            .observe('authToken')
            .subscribe(function (token) {
            if (!token) {
                _this.store.dispatch(new userActions.LogoutCompleteAction());
            }
        });
        this.route.queryParams
            .pipe(map(function (queryParams) { return queryParams.ccode; }), filter(function (value) { return value &&
            _this.route.snapshot.queryParams.bogo !== 'true' &&
            !_this.route.snapshot.queryParams.bradsdeals; }))
            .subscribe(function (coupon) {
            _this.store.dispatch(new couponsActions.VerifyCouponAction(coupon));
        });
        this.appService.initIntercom();
        this.displayMotherBanner = this.router.url === '/';
    };
    AppComponent.prototype.ngAfterViewInit = function () {
        // this.googleOptimizeService.optimizeActivate();
    };
    AppComponent.prototype.onCloseCouponBanner = function (event) {
        this.showCouponBanner = false;
        this.notificationBarHeight = 0;
        this.localStorage.store('showCouponBanner', false);
    };
    AppComponent.prototype.onCloseNotificationBar = function () {
        this.store.dispatch(new notificationBarActions.CloseNotificationBarAction());
    };
    AppComponent.prototype.onDeactivate = function () {
        this.appService.scrollToTop();
        // this.showCouponBanner = false;
    };
    return AppComponent;
}());
export { AppComponent };
