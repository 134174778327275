
  <div class="lead-banner-container" #bannerContainer>
    <div class="lead-banner-background"></div>
    <button class="close-lead-button" (click)="closeBanner()">X</button>
    <div class="lead-banner" #banner>
      <div class="lead-banner-content container-fluid">
        <div class="row justify-content-center" style="height: 100%">
          <div class="col-12 text-center mt-auto">
            <h1 class="lead-banner__title text-bolder">get plants that like you</h1>
          </div>
          <div class="col-12 text-center mb-5">
            <h3 class="lead-banner__subtitle">and $5 off! *</h3>
          </div>

          <form class="col-12 text-center lead-banner__form" [formGroup]="form">
            <div class="form-group mb-5">
              <input
                class="lead-input text-extralight"
                id="nameInput" type="email"
                placeholder="Enter your email"
                formControlName="email"
                required="required" />
              <span class="lead-error error-text" *ngIf="isFieldInvalid('email', 'invalidEmail')">Please use a valid email</span>
            </div>
            <button
              class="get-deal-button button button--primary mb-5"
              type="button"
              (click)="submitEmail()">GET THE DEAL</button>

            <span class="d-block lead-banner__help-text">
              *Offer applies to the first month of new subscriptions only.
              <br>
              Cannot be combined with other offers.
            </span>
          </form>

          <div class="col-12 text-center mt-auto mb-5">
            <a [attr.test-id]="'lead-banner__close-button'" class="lead-banner__link"
               (click)="closeBanner()">No thanks, I don’t want a $5 discount.
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  