import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { NgxWebstorageModule } from 'ngx-webstorage';
// @ngrx modules
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RouterStateSerializer, StoreRouterConnectingModule, } from '@ngrx/router-store';

// App modules
import { SharedModule } from './shared/shared.module';
import { PagesModule } from './pages/pages.module';
import { AuthModule } from './auth/auth.module';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

// configs
import { APP_CONFIG, app_config } from '@app/app.config';

import { ErrorsModule } from './error';

// root routing module
import { AppRoutingModule } from './app.routing';

// containers
import { AppComponent } from './app.component';

// services
import * as fromCoreServices from './core/services';

// resolvers
import { ClientAuthorizationResolver } from './core/resolvers/client-authorization.resolver';
import { PlanResolver } from './core/resolvers/plan.resolver';
import { ProfileResolver } from './core/resolvers/profile.resolver';

// guards
import { RouterTransitionGuard } from './core/guards/router-transition.guard';
import * as fromCoreGuards from './core/guards';

// components
import { ModalInfoComponent } from './core/components/modal-info.component';
import { OverlayModule } from '@angular/cdk/overlay';

// reducers
import { CustomSerializer, reducers } from '@app/store/reducers';

// effects
import * as fromEffects from './store/effects';
import { SubscriptionInfoResolver } from './core/resolvers/subscription-info.resolver';
import { NAV_INTERCEPTOR_PROVIDER } from './core/services/nav-Interceptor-init.service';

@NgModule({
  declarations: [
    AppComponent,
    ModalInfoComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    NgxWebstorageModule.forRoot({
      prefix: 'hnp', separator: '.', caseSensitive: true
    }),
    StoreModule.forRoot(reducers),
    StoreRouterConnectingModule,
    StoreDevtoolsModule.instrument({
      maxAge: 10
    }),
    EffectsModule.forRoot([...fromEffects.effects]),
    SharedModule,
    AuthModule,
    PagesModule,
    AppRoutingModule,
    ErrorsModule,
    RecaptchaV3Module,
  ],
  providers: [
    { provide: APP_CONFIG, useValue: app_config },
    NAV_INTERCEPTOR_PROVIDER,
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Lcc1LcZAAAAAIndXwzBa28HwVFVnXj9h6sokHR9' },
    RouterTransitionGuard,
    ClientAuthorizationResolver,
    PlanResolver,
    ProfileResolver,
    SubscriptionInfoResolver,
    ...fromCoreServices.services,
    ...fromCoreGuards.guards,
  ],
  entryComponents: [ModalInfoComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
