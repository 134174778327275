import * as tslib_1 from "tslib";
import { APP_BOOTSTRAP_LISTENER } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, NavigationCancel, NavigationError } from '@angular/router';
import { AppService, FacebookPixelService } from '.';
export var NAV_INTERCEPTOR_PROVIDER = {
    provide: APP_BOOTSTRAP_LISTENER,
    multi: true,
    useFactory: NavigationInterceptorInitializer,
    deps: [
        FacebookPixelService,
        Router,
        AppService
    ]
};
export function NavigationInterceptorInitializer($fpService, $router, $appService) {
    var _this = this;
    return function (c) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            $router.events
                .subscribe(function (event) {
                if (event instanceof NavigationStart) {
                    $appService.scrollToTop();
                    $appService.setAppLoading(true);
                    // console.log('loading');
                }
                if (event instanceof NavigationEnd) {
                    $fpService.pageView();
                    $appService.updateIntercom();
                    // console.log('loaded');
                }
                // Set loading state to false in both of the below events to hide the spinner in case a request fails
                if (event instanceof NavigationCancel || event instanceof NavigationEnd || event instanceof NavigationError) {
                    $appService.setAppLoading(false);
                }
            });
            return [2 /*return*/];
        });
    }); };
}
