import { Action } from '@ngrx/store';
import { Update } from '@ngrx/entity/src/models'

// models
import { PaymentMethod } from '@app/core/models/payment-method';

export const LOAD_PAYMENT_METHODS = '[PaymentMethod] Load Payment Method';
export const LOAD_PAYMENT_METHODS_COMPLETE = '[PaymentMethod] Load Payment Method Complete';
export const LOAD_PAYMENT_METHODS_FAIL = '[PaymentMethod] Load Payment Method Fail';

export const ADD_PAYMENT_METHOD = '[PaymentMethod] Add PaymentMethod';
export const ADD_PAYMENT_METHOD_COMPLETE  = '[PaymentMethod] Add Payment Method Complete';
export const ADD_PAYMENT_METHOD_FAIL  = '[PaymentMethod] Add Payment Method Fail';

export const UPDATE_PAYMENT_METHOD = '[PaymentMethod] Update PaymentMethod';
export const UPDATE_PAYMENT_METHOD_COMPLETE = '[PaymentMethod] Update Payment Method Complete';
export const UPDATE_PAYMENT_METHOD_FAIL = '[PaymentMethod] Update Payment Method Fail';

export const UPDATE_PAYMENT_METHOD_WITH_REBILL = '[PaymentMethod] Update PaymentMethod with Rebill';
export const UPDATE_PAYMENT_METHOD_WITH_REBILL_COMPLETE = '[PaymentMethod] Update Payment Method with Rebill Complete';
export const UPDATE_PAYMENT_METHOD_WITH_REBILL_FAIL = '[PaymentMethod] Update Payment Method with Rebill Fail';

export class LoadPaymentMethodsAction implements Action {
  readonly type = LOAD_PAYMENT_METHODS;
  constructor() { }
};

export class LoadPaymentMethodsCompleteAction implements Action {
  readonly type = LOAD_PAYMENT_METHODS_COMPLETE;
  constructor(public payload: PaymentMethod[]) { }
};

export class LoadPaymentMethodsFailAction implements Action {
  readonly type = LOAD_PAYMENT_METHODS_FAIL;
  constructor(public payload: any) { }
};

export class AddPaymentMethodAction implements Action {
  readonly type = ADD_PAYMENT_METHOD;
  constructor(public payload: { nonce: string, cardholderName: string }) { }
};

export class AddPaymentMethodCompleteAction implements Action {
  readonly type = ADD_PAYMENT_METHOD_COMPLETE;
  constructor(public payload: PaymentMethod) { }
};

export class AddPaymentMethodFailAction implements Action {
  readonly type = ADD_PAYMENT_METHOD_FAIL;
  constructor(public payload: any) { }
};

export class UpdatePaymentMethodAction implements Action {
  readonly type = UPDATE_PAYMENT_METHOD;
  constructor(public payload: { paymentMethodId: string, nonce: string, cardholderName: string }) { }
};

export class UpdatePaymentMethodCompleteAction implements Action {
  readonly type = UPDATE_PAYMENT_METHOD_COMPLETE;
  constructor(public payload: Update<PaymentMethod>) { }
};

export class UpdatePaymentMethodFailAction implements Action {
  readonly type = UPDATE_PAYMENT_METHOD_FAIL;
  constructor(public payload: any) { }
};

export class UpdatePaymentMethodWithRebillAction implements Action {
  readonly type = UPDATE_PAYMENT_METHOD_WITH_REBILL;
  constructor(public payload: { paymentMethodId: string, nonce: string, cardholderName: string }) { }
};

export class UpdatePaymentMethodWithRebillCompleteAction implements Action {
  readonly type = UPDATE_PAYMENT_METHOD_WITH_REBILL_COMPLETE;
  constructor(public payload: Update<PaymentMethod>) { }
};

export class UpdatePaymentMethodWithRebillFailAction implements Action {
  readonly type = UPDATE_PAYMENT_METHOD_WITH_REBILL_FAIL;
  constructor(public payload: any) { }
};

export type Actions =
  | LoadPaymentMethodsAction
  | LoadPaymentMethodsCompleteAction
  | LoadPaymentMethodsFailAction
  | AddPaymentMethodAction
  | AddPaymentMethodCompleteAction
  | AddPaymentMethodFailAction
  | UpdatePaymentMethodAction
  | UpdatePaymentMethodCompleteAction
  | UpdatePaymentMethodFailAction
  | UpdatePaymentMethodWithRebillAction
  | UpdatePaymentMethodWithRebillCompleteAction
  | UpdatePaymentMethodWithRebillFailAction;
