import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// rxjs
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// config
import { APP_CONFIG, AppConfig } from '@app/app.config';
// models
import { Address } from './../models/address.model';
// services
import { AuthService } from '@app/auth/services/auth.service';
import { isPlatformBrowser } from '@angular/common';
import { ConvertionService } from './convertion.service';

@Injectable()
export class AddressService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private convertionService: ConvertionService,
    @Inject(APP_CONFIG) private app_config: AppConfig,
    @Inject(PLATFORM_ID) private platformId: any
  ) { }

  getAddresses(): Observable<Address[]> {
    const userId = this.authService.tokenPayload._id;
    return this.httpClient
      .get(`${this.app_config.api_uri}/users/${userId}/address`)
      .pipe(map((result: any) => result.addresses as Address[]));
  }

  updateAddress({ _id, newData }): Observable<Address> {
    const userId = this.authService.tokenPayload._id;
    return this.httpClient
      .put(`${this.app_config.api_uri}/users/${userId}/address/${_id}`, newData)
      .pipe(map((result: any) => result.address as Address));
  }

  verifyAddress({ address }): Observable<Address> {
    return this.httpClient
      .post(`${this.app_config.api_uri}/public/address/validation`, { address })
      .pipe(catchError(e => {
        this.convertionService.reportManualAddressFailed();
        return throwError(e);
      }))
      .pipe(map((result: any) => result.address as Address));
  }
}
