import * as tslib_1 from "tslib";
import { ActivatedRoute } from '@angular/router';
import { OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LocalStorageService } from 'ngx-webstorage';
import { EmailValidator } from '@app/shared/validators/email.validator';
import { LeadsService } from '@app/core/services/leads.service';
import { LeadFormBaseComponent } from '../lead-form-base/lead-form-base.component';
import { ErrorService } from '@app/error';
import { NotificationBarService } from '@app/core/services/notification-bar.service';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
var WinItWednesdayLeadFormComponent = /** @class */ (function (_super) {
    tslib_1.__extends(WinItWednesdayLeadFormComponent, _super);
    function WinItWednesdayLeadFormComponent(route, leadService, errorService, localStorage, notificationService) {
        var _this = _super.call(this) || this;
        _this.route = route;
        _this.leadService = leadService;
        _this.errorService = errorService;
        _this.localStorage = localStorage;
        _this.notificationService = notificationService;
        return _this;
    }
    WinItWednesdayLeadFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form = new FormGroup({
            email: new FormControl('', EmailValidator.checkEmail),
            username: new FormControl('', [Validators.required]),
        });
        this.emailFormField = 'email';
        this.form.get('email').valueChanges
            .pipe(filter(function (data) { return typeof data === 'string' && data.indexOf('@') !== -1; }), debounceTime(1000), distinctUntilChanged())
            .pipe(takeUntil(this.destroyComponent$))
            .subscribe(function (email) {
            _this.testSuggestedEmails(email);
        });
    };
    WinItWednesdayLeadFormComponent.prototype.submitLeadForm = function () {
        var _this = this;
        var email = this.form.get('email').value;
        var username = this.form.get('username').value;
        if (this.form.invalid) {
            return;
        }
        this.createLead(email, username)
            .then(function () {
            _this.submit.emit({ email: email });
            _this.closeLeadForm();
        })
            .catch(function (error) {
            _this.closeLeadForm();
            var message = "Error trying to create/update Lead.";
            _this.errorService.client.notify(error, {
                beforeSend: function (report) {
                    report.severity = 'warning';
                    report.updateMetaData('extras', {
                        http: true,
                        client: true,
                        message: message,
                        error: error,
                    });
                }
            });
        });
    };
    WinItWednesdayLeadFormComponent.prototype.createLead = function (email, username) {
        var _this = this;
        if (email === void 0) { email = ''; }
        if (username === void 0) { username = ''; }
        var uuid = this.localStorage.retrieve('uuid');
        var utmData = this.route.snapshot.queryParams;
        this.localStorage.store('customerEmail', email);
        return this.leadService
            .createNewWinItWednesdayEntry({ uuid: uuid, email: email, utmData: utmData, leadForm: 'five-off', social: { instagram: username } })
            .toPromise()
            .then(function (data) {
            _this.notificationService.open({ title: 'Entry Submitted!', message: '' });
            return data;
        });
    };
    WinItWednesdayLeadFormComponent.prototype.isFieldInvalid = function (field, validation) {
        var control = this.form.get(field);
        return control.hasError(validation) && control.touched;
    };
    return WinItWednesdayLeadFormComponent;
}(LeadFormBaseComponent));
export { WinItWednesdayLeadFormComponent };
