import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { HttpEventType } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
// actions
import * as fromUserActions from './../actions/user.actions';
import * as searchEmail from './../actions/search-email.actions';
import * as paymentMethod from './../actions/payment-methods.actions';
import * as subscriptionActions from './../actions/subscription.action';
// models
// services
import { AuthService } from '@app/auth/services/auth.service';
import { UserService } from '@app/core/services/user.service';
import { ErrorService } from '@app/error';
import { Router } from '@angular/router';
import { ConvertionService } from '@app/core/services/convertion.service';
var AuthEffects = /** @class */ (function () {
    function AuthEffects(authService, userService, actions$, store, errorService, router, platformId, convertionService) {
        var _this = this;
        this.authService = authService;
        this.userService = userService;
        this.actions$ = actions$;
        this.store = store;
        this.errorService = errorService;
        this.router = router;
        this.platformId = platformId;
        this.convertionService = convertionService;
        this.load$ = this.actions$
            .pipe(ofType(fromUserActions.LOAD), switchMap(function () {
            if (!_this.authService.tokenPayload) {
                return of(new fromUserActions.LoadFailAction([{ message: 'User not authenticated!' }]));
            }
            return _this.authService.profile()
                .pipe(map(function (data) {
                _this.store.dispatch(new fromUserActions.LoadCompleteAction(data.user));
                _this.errorService.setClientReporterUser(data.user._id, data.user.fullName, data.user.email);
                // this.store.dispatch(new paymentMethod.LoadPaymentMethodsCompleteAction(data.user.paymentMethods));
            }), catchError(function (reason) {
                _this.store.dispatch(new fromUserActions.LoadFailAction(reason.errors));
                _this.authService.removeJWTToken();
                return of(new fromUserActions.LoadFailAction(reason.errors));
            }));
        }));
        this.login$ = this.actions$
            .pipe(ofType(fromUserActions.LOGIN), map(function (action) { return action.payload; }), switchMap(function (auth) { return _this.authService.loginUser(auth)
            .pipe(map(function (event) {
            if (event.type === HttpEventType.Response) {
                var user = event.body.user;
                // Set FullStory user
                _this.convertionService.setFullstoryUser({ user: user });
                _this.convertionService.setIdentify({ user: user });
                _this.errorService.setClientReporterUser(user._id, user.fullName, user.email);
                _this.store.dispatch(new fromUserActions.LoginCompleteAction(user));
            }
        }), catchError(function (reason) {
            _this.store.dispatch(new fromUserActions.LoginFailAction(reason.error));
            return of(new fromUserActions.LoginFailAction(reason.error));
        })); }));
        this.update$ = this.actions$
            .pipe(ofType(fromUserActions.UPDATE), map(function (action) { return action.payload; }), switchMap(function (userData) {
            return _this.userService.updateAccount(userData)
                .pipe(map(function (event) {
                if (event.type === HttpEventType.Response) {
                    _this.store.dispatch(new fromUserActions.UpdateCompleteAction(event.body.user));
                }
            }), catchError(function (reason) {
                _this.store.dispatch(new fromUserActions.UpdateFailAction(reason.error));
                return of(null);
            }));
        }));
        this.updateName$ = this.actions$
            .pipe(ofType(fromUserActions.UPDATE_NAME), map(function (action) { return action.payload; }), switchMap(function (_a) {
            var firstName = _a.firstName, lastName = _a.lastName, _id = _a._id;
            return _this.userService.updateName({ firstName: firstName, lastName: lastName, _id: _id })
                .pipe(map(function (data) {
                var mapToUpdate = function (subscription) {
                    subscription.plan = subscription.plan._id;
                    return {
                        id: subscription._id,
                        changes: subscription
                    };
                };
                _this.store.dispatch(new fromUserActions.UpdateCompleteAction(data.user));
                _this.store.dispatch(new subscriptionActions.UpdateSubscriptionsCompleteAction(data.updatedSubscriptions.map(mapToUpdate)));
            }), catchError(function (reason) {
                _this.store.dispatch(new fromUserActions.UpdateFailAction(reason.error));
                return of(null);
            }));
        }));
        this.register$ = this.actions$
            .pipe(ofType(fromUserActions.CREATE), map(function (action) { return action.payload; }), switchMap(function (auth) {
            var firstName = auth.firstName, lastName = auth.lastName, email = auth.email, password = auth.password, phone = auth.phone;
            return _this.authService.createUser({ firstName: firstName, lastName: lastName, email: email, password: password, phone: phone })
                .pipe(map(function (event) {
                // if (event.type === HttpEventType.Sent) {
                //   this.store.dispatch(new ui.ShowUserCreateLoaderAction());
                // }
                if (event.type === HttpEventType.Response) {
                    _this.store.dispatch(new fromUserActions.CreateCompleteAction(event.body.user));
                }
            }), catchError(function (reason) {
                _this.store.dispatch(new fromUserActions.CreateFailAction(reason.error));
                return of(null);
            }));
        }));
        this.logout$ = this.actions$
            .pipe(ofType(fromUserActions.LOGOUT), map(function () {
            // Clear FullStory user
            _this.convertionService.clearFullstoryUser();
            _this.convertionService.clearIdentify();
            _this.errorService.unsetClientReporterUser();
            _this.authService.logout();
            // this.store.dispatch(new user.LogoutCompleteAction());
            _this.store.dispatch(new paymentMethod.LoadPaymentMethodsCompleteAction([]));
        }));
        this.logoutComplete$ = this.actions$
            .pipe(ofType(fromUserActions.LOGOUT_COMPLETE), tap(function () {
            _this.router.navigate(['/']);
        }));
        this.searchEmail$ = this.actions$
            .pipe(ofType(searchEmail.SEARCH_EMAIL), map(function (action) { return action.payload; }), switchMap(function (email) { return _this.authService.checkUserExist(email)
            .pipe(map(function (event) {
            // if (event.type === HttpEventType.Sent) {
            //  this.store.dispatch(new ui.ShowSearchEmailLoaderAction());
            // }
            if (event.type === HttpEventType.Response) {
                _this.store.dispatch(new searchEmail.SearchEmailCompleteAction());
            }
        }), catchError(function (reason) {
            _this.store.dispatch(new searchEmail.SearchEmailFailAction(reason.errors));
            return of(new searchEmail.SearchEmailFailAction(reason.errors));
        })); }));
    }
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "load$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "login$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "update$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "updateName$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "register$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "logout$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "logoutComplete$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], AuthEffects.prototype, "searchEmail$", void 0);
    return AuthEffects;
}());
export { AuthEffects };
