<!-- <div class="thanks-container">

  <div class="thanks-title-container">
    <h1 class="thanks-title">{{ giftDoneData?.title }}</h1>
    <p class="thanks-title-message">{{ giftDoneData?.message }}</p>

    <div class="thanks-title-divider">
      <img src="/assets/images/thanks-icon.png"/>
      <span class="gray-line"></span>
    </div>

    <p class="term-message">
      Subscription automatically ends after the {{ this.data.subscription.plan.term | ordinal }} month, so no need
      to cancel!
    </p>
  </div>
  <video *ngIf="giftDoneData?.video" class="video-container" [poster]="giftDoneData?.video.poster" controls preload="metadata">
    <source [src]="giftDoneData?.video.srcmp4" type="video/mp4">
    <source [src]="giftDoneData?.video.srcwebm" type="video/webm">
    Your browser does not support the video tag.
  </video>
  <div class="discount-btn-section">
    <p>{{ brandData?.mainProduct === 'succulents' ? brandData?.mainProduct + 'brighten even the succ-iest of days!' : '' }}  Want your own subscription?</p>
    <a class="btn discount-btn" (click)="goToCheckoutWithCoupon()">GET $5 OFF YOUR FIRST BOX</a>
  </div>
</div> -->

<div class="container-fluid">
  <div class="row">
    <!-- <div class="thanks-title-divider">
      <img src="/assets/images/thanks-icon.png"/>
      <span class="gray-line"></span>
    </div> -->

    <section class="col-12">
      <div class="gift-section">
        <div class="row gift-done-content justify-content-center">
          <div class="col-12 text-center gift-title-container">
            <h2 class="gift-title">{{ giftDoneData?.title }}</h2>
          </div>
          <div class="col-12 text-center gift-subtitle-margin">
            <p class="gift-subtitle">
              Thank you for subscribing!
            </p>
          </div>
          <div class="col-8 text-center gift-done-text-margin">
            <p class="gift-done-text" [innerHTML]="giftDoneData?.message | safeHtml"></p>
          </div>

          <div class="col-12 text-center gift-link">
            <svg class="gift-link__back-icon" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 24 24" fill="white" width="18px" height="18px">
              <path d="M0 0h24v24H0z" fill="none"/>
              <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"/>
            </svg>
            <a class="gift-link__home-link" routerLink="/">HOMEPAGE</a>
          </div>

<!--
          <div class="col-12 text-center gift-image-section">
            <div class="gift-image-section__image" appBgDinamic
                 [bgDinamicXS]="giftDoneData?.image"></div>
          </div>
-->

           <video *ngIf="giftDoneData?.video" class="video-container" [poster]="giftDoneData?.video.poster" controls preload="metadata">
            <source [src]="giftDoneData?.video.srcmp4" type="video/mp4">
            <source [src]="giftDoneData?.video.srcwebm" type="video/webm">
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </section>
  </div>
</div>

