// import {
//   trigger,
//   state,
//   style,
//   animate,
//   transition
// } from '@angular/animations';

import { ActivatedRoute, Router } from '@angular/router';

import { AfterViewInit, Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { environment } from '@env/environment';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { select, Store } from '@ngrx/store';
// RxJS
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
// services
import { AuthService } from '@app/auth/services/auth.service';
import { AppService, ConfigService, GoogleOptimizeService, LeadsService, CouponsService } from '@app/core/services';
import { ErrorService } from './error/services';
// actions
import * as userActions from '@app/store/actions/user.actions';
import * as couponsActions from '@app/store/actions/coupon.actions';
import * as notificationBarActions from '@app/store/actions/notification-bar.actions';
// selectors
import * as fromRoot from '@app/store/selectors';
import * as fromUser from '@app/store/selectors/user.selector';

// components
import { MothersBannerComponent } from '@app/shared/components/mothers-banner/mothers-banner.component';
import { paramsMap } from '@app/core/mappers/params-map.mapper';
import { isPlatformBrowser, Location } from '@angular/common';
import { Coupon } from './core/models/coupon.model';

declare let fbq: Function;

@Component({
  selector: 'sucstu-root',
  template: `
    <div class="app-loading" *ngIf="(appLoading$ | async) || !isBrowser">
      <div class="logo"></div>
      <svg class="spinner" viewBox="25 25 50 50">
        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
      </svg>
    </div>
    <div
      class="content">
      <!-- [@appState]="state" -->
      <div class="main">

        <sucstu-notification-bar
          [data]="(notificationBar$ | async)"
          (close)="onCloseNotificationBar()">
        </sucstu-notification-bar>

        <sucstu-coupon-banner
          [showBanner]="showCouponBanner"
          [coupon]="coupon$ | async"
          (close)="onCloseCouponBanner($event)">
        </sucstu-coupon-banner>

        <sucstu-header
          [moveFromTop]="notificationBarHeight"
          [ngClass]="{ 'moveByBanner': showCouponBanner || (notificationBar$ | async).open }">
        </sucstu-header>

        <div class="app-container">
          <router-outlet (deactivate)="onDeactivate()"></router-outlet>
        </div>

      </div>
      <sucstu-footer class="footer-container"></sucstu-footer>
    </div>
  `,
  styleUrls: ['./app.component.scss'],
  // animations: [
  //   trigger('appState', [
  //     state('inactive', style({ opacity: '0' })),
  //     state('active', style({ opacity: '1' })),
  //     transition('inactive <=> active', animate('500ms ease-out'))
  //   ])
  // ]
})
export class AppComponent implements OnInit, AfterViewInit {
  isBrowser = isPlatformBrowser(this.platformId);

  @ViewChild(MothersBannerComponent, { read: ElementRef }) mothersBannerComponent: ElementRef;

  user$: Observable<fromUser.State>;
  notificationBar$: Observable<any>;
  coupon$: Observable<Coupon>;
  sessionCoupon: Coupon;

  appLoading$: Observable<boolean> = this.appService.appLoading$.asObservable();
  state = 'inactive';
  showCouponBanner: boolean;

  notificationBarHeight = 0;
  displayMotherBanner = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appService: AppService,
    private authService: AuthService,
    private leadService: LeadsService,
    private errorService: ErrorService,
    private store: Store<fromRoot.State>,
    private couponService: CouponsService,
    private configService: ConfigService,
    private localStorage: LocalStorageService,
    private sessionStorage: SessionStorageService,
    private googleOptimizeService: GoogleOptimizeService,
    @Inject(PLATFORM_ID) private platformId: any,
    private location: Location
  ) {
    this.couponService.init();
    this.coupon$ = this.couponService.coupon$;
  }

  ngOnInit() {
    this.user$ = this.store.pipe(select(fromRoot.getUserState));
    this.notificationBar$ = this.store.pipe(select(fromRoot.getNotificationBarState));
    this.sessionCoupon = JSON.parse(this.sessionStorage.retrieve('coupon'));
    this.showCouponBanner = JSON.parse(this.localStorage.retrieve('showCouponBanner'));

    this.configService.getConfig().subscribe((data) => {
      this.sessionStorage.store('config', data.config);
    });

    this.appService.init();

    // Clear LS props when needed.
    const currentAppVersion = this.localStorage.retrieve('appVersion');
    if (!currentAppVersion || currentAppVersion !== environment.app_version) {
      this.localStorage.store('appVersion', environment.app_version);
      Object.keys(environment.propsToClearOnLS)
        .filter(key => environment.propsToClearOnLS[key])
        .forEach(key => this.localStorage.clear(key));
    }

    // this.googleOptimizeService.setGoExperiments();

    // Set UUID in order to be capable
    // of recognize the client when the lead
    // data is saved/updated
    const availableAffiliateRefs = ['clickref', 'clickRef'];
    const availableLeadsProps = [
      'utm_medium',
      'utm_term',
      'utm_campaign',
      'utm_source',
      'utm_content',
      'http_referrer_url',
      'source'
    ];

    const queryParams$ = this.route.queryParams
    .pipe(
      filter((data: any) => !!Object.keys(data).length),
      map(paramsMap),
    );

    const utmData$ = queryParams$.pipe(
      filter((data: any) => {
        const value = Object.keys(data)
          .findIndex(key => availableLeadsProps.includes(key));
        return value !== -1;
      }),
    );

    const affiliateRef$ = queryParams$.pipe(
      map((data: any) => {
        const key = Object.keys(data).find(item => availableAffiliateRefs.includes(item));
        return data[key] || null;
      }),
      filter(data => !!data)
    ).subscribe(data => {
      this.sessionStorage.store('affiliateRef', data);
    });

    const storedUUID = this.localStorage.retrieve('uuid');
    const getUUID$ = storedUUID ? of(storedUUID) :
      this.appService.getUUID().pipe(tap((uuid) => this.localStorage.store('uuid', uuid)));

    getUUID$
      .pipe(
        take(1),
        switchMap(uuid => {
          return utmData$.pipe(map(utmData => ({ uuid, params: utmData })));
        }),
        switchMap(({ uuid, params }) => {
          console.log('Here is', uuid, params);
          this.sessionStorage.store('utmData', {
            ...params,
            utm_source: params.utm_source || params.source,
          });
          return this.leadService.create({ uuid, utmData: params });
        }),
      ).subscribe(
      (data) => console.log(data),
      (error) => {
        const message = `Error trying to create UUID/Lead.`;
        this.errorService.client.notify(error, {
          beforeSend: report => {
            report.severity = 'warning';
            report.updateMetaData('extras', {
              http: true,
              client: true,
              message,
              error,
            });
          }
        });
      }
    );

    combineLatest([
      this.user$,
      this.route.queryParams.pipe(
        map(paramsMap),
        filter(queryParams => Object.keys(queryParams).length > 0 === this.location.path().includes('?')),
        map(queryParams => queryParams.token),
      )
    ])
      .pipe(
        switchMap(([user, token]) => {
          if (token && token.length && this.authService.token !== token) {
            this.authService.setJWTToken(token);
            return this.authService.checkToken(token).pipe(
              switchMap(() => of([user, token])),
              catchError(error => {
                this.authService.unsetJWTToken();
                this.router.navigate(['/login']);
                return of([user, null]);
              })
            );
          }
          return of([user, token]);
        })
      )
      .subscribe(([user, token]) => {
        if (
          token && token.length && this.authService.token !== token
          || !user.isAuthenticated && this.authService.token
        ) {
          this.store.dispatch(new userActions.LoadAction());
        }
      });

      this.user$
      .subscribe((user: fromUser.State) => {
        if (!user.isAuthenticated && this.authService.token) {
          this.store.dispatch(new userActions.LoadAction());
        }
      });

    this.coupon$
      .subscribe((coupon: Coupon) => {
        if (coupon) {
          this.localStorage.store('showCouponBanner', true);
          this.sessionStorage.store('coupon', JSON.stringify(coupon));
          this.showCouponBanner = true;
          return;
        }

        this.localStorage.store('showCouponBanner', false);
        this.showCouponBanner = false;
      });

    this.localStorage
      .observe('authToken')
      .subscribe(token => {
        if (!token) {
          this.store.dispatch(new userActions.LogoutCompleteAction());
        }
      });

      this.route.queryParams
      .pipe(
        map(queryParams => queryParams.ccode),
        filter((value) => value &&
          this.route.snapshot.queryParams.bogo !== 'true' &&
          !this.route.snapshot.queryParams.bradsdeals),
      )
      .subscribe(coupon => {
        this.store.dispatch(new couponsActions.VerifyCouponAction(coupon));
      });

    this.appService.initIntercom();

    this.displayMotherBanner = this.router.url === '/';
  }

  ngAfterViewInit() {
    // this.googleOptimizeService.optimizeActivate();
  }

  onCloseCouponBanner(event: any) {
    this.showCouponBanner = false;
    this.notificationBarHeight = 0;
    this.localStorage.store('showCouponBanner', false);
  }

  onCloseNotificationBar() {
    this.store.dispatch(new notificationBarActions.CloseNotificationBarAction());
  }

  onDeactivate() {
    this.appService.scrollToTop();
    // this.showCouponBanner = false;
  }

}
