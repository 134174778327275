import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'hammerjs';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// GTM script to <head>
if (!document.getElementById('gtm-head-script')) {
  const gtmHeadScript = document.createElement('script');
  gtmHeadScript.setAttribute('id', 'gtm-head-script');
  gtmHeadScript.innerHTML =
  `(function (w, d, s, l, i) {
    w[l] = w[l] || []; w[l].push({
      'gtm.start':
        new Date().getTime(), event: 'gtm.js'
    }); var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl + '${environment.gtmEnvironment}';
      f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', '${environment.gtmContainerId}');`;
  document.head.appendChild(gtmHeadScript);
}

// Google Optimize AntiFlicker
// if (!document.getElementById('g-optimize-antiflicker-s')) {
//   const googleOptimizeAntiFlickerSnippet = document.createElement('script');
//   googleOptimizeAntiFlickerSnippet.setAttribute('id', 'g-optimize-antiflicker-s');
//   googleOptimizeAntiFlickerSnippet.innerHTML =
//   `
//   (function (a, s, y, n, c, h, i, d, e) {
//     s.className += ' ' + y;
//     h.start = 1 * new Date;
//     h.end = i = function () {
//       s.className = s.className.replace(RegExp(' ?' + y), '')
//     };
//     (a[n] = a[n] || []).hide = h;
//     setTimeout(function () {
//       i();
//       h.end = null
//     }, c);
//     h.timeout = c;
//   })(window, document.documentElement, 'async-hide', 'dataLayer', 4000, { '${environment.gtmContainerId}': true });`;
//   document.head.appendChild(googleOptimizeAntiFlickerSnippet);
// }

// GTM noscript for body
if (!document.getElementById('gtm-body-noscript')) {
  const gtmBodyScript = document.createElement('noscript');
  gtmBodyScript.setAttribute('id', 'gtm-body-noscript');
  const gtmBodyIframe = document.createElement('iframe');
  gtmBodyIframe.setAttribute(
    'src',
    `https://www.googletagmanager.com/ns.html?id=${environment.gtmContainerId}${environment.gtmEnvironment}`
  );
  gtmBodyIframe.setAttribute('height', '0');
  gtmBodyIframe.setAttribute('width', '0');
  gtmBodyIframe.setAttribute('style.display', 'none');
  gtmBodyIframe.setAttribute('style.visibility', 'hidden');

  gtmBodyScript.appendChild(gtmBodyIframe);
  document.body.appendChild(gtmBodyScript);
}

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule);
});
