import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, Location } from '@angular/common';
import * as BrandData from '@config/brand.json'
import * as FooterData from '@config/footer.json'
import * as CarecardsData from '@config/carecards.json';
import * as ModulesData from '@config/modules.json';
import { LocalStorageService } from 'ngx-webstorage';
import * as fromServices from './../../../core/services';
import { APP_CONFIG, AppConfig } from '@app/app.config';
import { ActivatedRoute } from '@angular/router';
import { ErrorService } from './../../../error';
import { FormBuilder } from '@angular/forms';
import { EmailValidator } from './../../../shared/validators/email.validator';

@Component({
  selector: 'sucstu-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <footer class="container-fluid" *ngIf="!isSales" [ngClass]="{'footer--alternative': alternativeFooter}">
      <div class="row">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col">
              <form [formGroup]="form" class="row" (ngSubmit)="createLead()">
                <div class="col-auto align-center--mobile footer-input-wrapper">
                  <input
                    class="footer-input"
                    type="email"
                    placeholder="Your email address"
                    formControlName="email"
                    required="required"/>
                  <span class="footer-lead-error" *ngIf="isFieldInvalid('email', 'invalidEmail')">
                    Please use a valid email
                  </span>
                </div>
                <div class="col-auto align-self-end align-center--mobile subscribe-button-wrapper">
                  <button [disabled]="!form.valid" class="button button--smaller" type="submit"
                          [ngClass]="{'button--white': !alternativeFooter, 'button--primary': alternativeFooter}">
                    GET OFFERS
                  </button>
                </div>
              </form>
            </div>

            <div class="col-auto align-center--mobile">
              <div class="row">
                <div class="col-auto mr-3">
                  <span routerLink="/about" class="footer-links d-block mb-3">about</span>
                  <a
                    *ngIf="brandData?.socialMedia?.facebook"
                    class="social-link__item"
                    [href]="brandData.socialMedia.facebook"
                    target="a_blank">
                    <img src="/assets/images/icons/social-media/facebook.svg" class="footer-social-media-icon" alt="Facebook Icon">
                  </a>
                </div>
                <div class="col-auto mr-3">
                  <span routerLink="/faq" class="footer-links d-block mb-3">faq</span>
                  <a
                    *ngIf="brandData?.socialMedia?.instagram"
                    class="social-link__item"
                    [href]="brandData.socialMedia.instagram"
                    target="a_blank">
                    <img src="/assets/images/icons/social-media/instagram.svg" class="footer-social-media-icon" alt="Instagram Icon">
                  </a>
                </div>
                <div class="col-auto mr-3">
                  <span routerLink="/gift" class="footer-links d-block mb-3">gift</span>
                  <a
                    *ngIf="brandData?.socialMedia?.pinterest"
                    class="social-link__item"
                    [href]="brandData.socialMedia.pinterest"
                    target="a_blank">
                    <img src="/assets/images/icons/social-media/pinterest-light.svg" class="footer-social-media-icon" alt="Pinterest Icon">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  `
})
export class FooterComponent {
  footerData = (FooterData as any).default;
  brandData = (BrandData as any).default;
  carecardsData = (CarecardsData as any).default;
  modulesData = (ModulesData as any).default;

  form = this.fb.group({
    email: ['', EmailValidator.checkEmail]
  });

  constructor(
    private errorService: ErrorService,
    private fb: FormBuilder,
    private locationService: Location,
    private localStorage: LocalStorageService,
    private leadService: fromServices.LeadsService,
    private route: ActivatedRoute,
    @Inject(APP_CONFIG) private app_config: AppConfig,
    @Inject(PLATFORM_ID) private platformId: any) {
  }

  get isSales() {
    return this.locationService.path() === '/sales' || this.locationService.path() === '/churn';
  }

  get alternativeFooter() {
    return this.locationService.path().includes('/checkout') ||
      this.locationService.path().includes('/faq') ||
      this.locationService.path() === '/gift' ||
      this.locationService.path().includes('/quiz');
  }

  moveToTop() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }
  }

  createLead(): void {
    const uuid = this.localStorage.retrieve('uuid');
    const utmData = this.route.snapshot.queryParams;
    const email = this.form.get('email').value;
    this.localStorage.store('customerEmail', email);
    console.log(uuid, utmData, email);
    this.leadService
      .create({uuid, email, utmData})
      .subscribe(
        () => {
          this.form.reset();
        },
        error => {
          const message = `Error trying to create/update Lead.`;
          this.errorService.client.notify(error, {
            beforeSend: report => {
              report.severity = 'warning';
              report.updateMetaData('extras', {
                http: true,
                client: true,
                message,
                error,
              });
            }
          });
        }
      );
  }

  isFieldInvalid(field: string, validation: string) {
    const control = this.form.get(field);
    return control.hasError(validation) && control.touched;
  }

}
