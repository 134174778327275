<div class="lead-banner">
  <a class="close" (click)="closeLeadForm()">
    <span class="sr-only">close</span>
  </a>

  <p class="logo-title"><span>Hey Nice Plants</span></p>

  <div class="lead-banner-content">

    <p class="lead-banner__title">
      <span class="text-bolder">WE'VE GOT SUCCS</span>
      <br>
      GET FREE SHIPPING ON
      <br>
      <span class="text-bolder">YOUR FIRST BOX!</span>
    </p>

    <form class="lead-banner__form" [formGroup]="form">
      <div class="form-group">
        <input
          class="lead-input form-group__input text-extralight"
          id="nameInput"
          type="email"
          placeholder="Enter your email"
          formControlName="email"
          required="required"
        />
        <span
          class="lead-error error-text"
          *ngIf="isFieldInvalid('email', 'invalidEmail')"
          >Please use a valid email</span
        >
      </div>
      <button
        class="lead-banner__btn btn btn-primary"
        type="button"
        (click)="submitLeadForm()"
      >
        I want succulents
      </button>
    </form>
    <span *ngIf="(showSuggestedEmail$ | async)" class="user-suggested-email">
      Do you mean <strong (click)="saveSuggestedEmail()">{{ suggestedEmail$ | async }}</strong>?
    </span>
  </div>

  <a [attr.test-id]="'lead-banner__close-button'" class="term-text" (click)="closeLeadForm()">No thanks, I don’t want free shipping</a>
</div>
