import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
@Injectable({
  providedIn: 'root',
})
export class FacebookPixelService {
  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  pageView() {
    if (isPlatformBrowser(this.platformId) && 'fbq' in window) {
      (window as any).fbq('track', 'PageView');
    }
  }
}
