<div #leadBannerComponent></div>


<!-- <sucstu-modal-banner></sucstu-modal-banner> -->
<!-- Hero section (background background with image and hero messages)-->
<section class="hero-container container-fluid"  *ngIf="isBrowser">

  <div class="hero-slides-container">
    <div class="mobile-hero-slide">
      <owl-carousel #owlMobile [options]="mobileSliderOptions" [carouselClasses]="['owl-custom-theme']">
        <div *ngFor="let slide of homeData?.mainCarousel">
          <div class="slide-picture" appBgDinamic
               [bgDinamicXS]="slide.images?.mobile"
               [bgDinamicSM]="slide.images?.tablet"
               [bgDinamicMD]="slide.images?.desktop">
            <div class="hero-title-container">
              <h2 class="hero-title" [ngClass]="{ 'hero-title--dark': slide.alternativeStyle }">
                {{ slide.title }}
              </h2>
              <button class="button button--primary mt-5" routerLink="/checkout">
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </owl-carousel>
    </div>

    <div class="desktop-hero-slide">
      <owl-carousel #owlDesk [options]="mobileSliderOptions">
        <div *ngFor="let slide of homeData?.mainCarousel">
          <div class="slide-picture" appBgDinamic [bgDinamicXS]="slide.images?.mobile"
               [bgDinamicSM]="slide.images?.tablet"
               [bgDinamicMD]="slide.images?.desktop"
               [ngStyle]="{ 'background': slide.topEffect ? 'none !important' : '' }">
              <div class="hero__item" [class.hero__item--dark]="slide.darkBackground">
                <div class="hero-item__container">
                  <h2 class="hero__item__text" [ngClass]="{ 'hero__item__text--alternative': slide.alternativeStyle }">
                    {{ slide.title }}
                  </h2>
                  <button class="button button--primary" routerLink="/checkout">
                    Subscribe
                  </button>
                  <div class="hero-item__spacer"></div>
                </div>
                <!--<div class="div">
                  <button class="button button&#45;&#45;primary hero__item__button" (click)="selectPlan()">{{ slide.buttonText }}</button>
                </div>-->
              </div>
              <div *ngIf="slide.topEffect" class="hero-image-ontop" appBgDinamic [bgDinamicXS]="slide.images?.mobile"
                   [bgDinamicSM]="slide.images?.tablet"
                   [bgDinamicMD]="slide.images?.desktop"></div>
          </div>
        </div>
      </owl-carousel>
    </div>
  </div>

  <!--<div class="slide-nav slide-nav-container-right">
    <div class="nav-circle" (click)="moveSliderToRight()">
      <img src="/assets/images/right-arrow.png"/>
    </div>
  </div>-->
</section>

<section class="container your-nice-plants mb-5">
  <div class="row align-items-center">
    <div class="col-lg-6">
      <div class="row your-nice-plants__text-wrapper justify-content-center align-content-center">
        <div class="col-12">
          <h2 class="title title--primary title--small-dekstop">your nice plants</h2>
        </div>

        <div class="col-12">
          <p class="your-nice-plants__simple-text--subtitle">
            One surprise houseplant every month, guaranteed to like you.
          </p>
        </div>

        <div class="col-12">
          <div class="your-nice-plants__text-divider"></div>
        </div>

        <div class="col-12">
          <p class="your-nice-plants__simple-text mb-5">
            Ships in a 4" pot; complete with care instructions and ready to thrive. Growing your houseplant family have never been this easy ... or looked this nice. Just $19/mo.
          </p>
        </div>

        <div class="col-12 text-center your-nice-plants-button-wrapper">
          <button class="button button--primary" routerLink="/checkout">GET NICE PLANTS</button>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <img src="/assets/images/home/A-begonia.gif" alt="a-begonia-gift">
    </div>
  </div>
</section>

<section class="home-quiz-banner container-fluid">
  <div class="row justify-content-center align-content-center" style="height: 100%">
    <div class="col-auto text-center">
      <h2 class="title title--white title--small-dekstop">
        plants that like you
      </h2>

      <button class="button button--white" routerLink="/checkout">
        Subscribe
      </button>
    </div>
  </div>
</section>

<section class="container mb-5 mt-5 what-you-get-wrapper">
  <div class="row">
    <div class="col-lg-6 order-lg-last">
      <div class="row what-you-get__text-wrapper justify-content-center align-content-center">
        <div class="col-12">
          <h2 class="title title--primary title--small-dekstop">what you get</h2>
        </div>

        <div class="col-12">
          <p class="what-you-get__simple-text mb-5">
            <b>Plant killers, plant collectors, plant decorators…</b>
            <br>
            it’s time to get plants that like YOU. Shipped with detailed care instructions from our farm in Southern California, we send you monthly houseplants curated just for you.
          </p>
        </div>

        <div class="col-12">
          <p class="what-you-get__simple-text your-nice-plants__simple-text--bold mb-5">
            SKIP or GIFT any month | Cancel any time.
            <br>
            100% satisfaction guaranteed.
          </p>
        </div>
      </div>
    </div>
    <div class="col-lg-6 what-you-get-image-container">
      <img src="/assets/images/home/D-moneytree_working.gif" alt="">
    </div>
  </div>
</section>

<section class="home-review container">
  <div class="d-none d-xl-block">
    <div class="row">
      <div class="col-12 col-xl-3 home-review__card--desktop">
        <img class="home-review__open-quote" src="/assets/images/icons/open-quote.svg"/>
        <img class="home-review__close-quote" src="/assets/images/icons/open-quote.svg"/>
        <div class="home-review__item">
          <p class="home-review__item__name">“My first plant arrived perfectly packaged! The care instructions are super clear so hopefully I can keep it alive!”</p>
          <span class="home-review__item__name">-Margaret; NJ</span>
        </div>
      </div>
      <div class="col-12 col-xl-3 home-review__card--desktop">
        <img class="home-review__open-quote" src="/assets/images/icons/open-quote.svg"/>
        <img class="home-review__close-quote" src="/assets/images/icons/open-quote.svg"/>
        <div class="home-review__item">
          <p class="home-review__item__name">“I've gotten two really nice plants from Hey Nice Plants so far, and they're both thriving! My apartment looks like a real adult lives here.”</p>
          <span class="home-review__item__name">-Julian; MN</span>
        </div>
      </div>
      <div class="col-12 col-xl-3 home-review__card--desktop">
        <img class="home-review__open-quote" src="/assets/images/icons/open-quote.svg"/>
        <img class="home-review__close-quote" src="/assets/images/icons/open-quote.svg"/>
        <div class="home-review__item">
          <p class="home-review__item__name">“I don't really trust plants from Trader Joe's, they always seem to die on me. My first plant from HNP arrived really healthy and perked right up after its first watering!”</p>
          <span class="home-review__item__name">-Angel; NY</span>
        </div>
      </div>
      <div class="col-12 col-xl-3 home-review__card--desktop">
        <img class="home-review__open-quote" src="/assets/images/icons/open-quote.svg"/>
        <img class="home-review__close-quote" src="/assets/images/icons/open-quote.svg"/>
        <div class="home-review__item">
          <p class="home-review__item__name">“As someone who doesn't know which plants are best for me and my space, I am obsessed with this subscription.”</p>
          <span class="home-review__item__name">-Heather; CO</span>
        </div>
      </div>
    </div>

  </div>

  <owl-carousel class="d-xl-none" #owlMobile [options]="mobileSliderOptions" [carouselClasses]="['owl-custom-theme']">
    <div class="home-review__card">
      <img class="home-review__open-quote" src="/assets/images/icons/open-quote.svg"/>
      <img class="home-review__close-quote" src="/assets/images/icons/open-quote.svg"/>
      <div class="home-review__item">
        <p class="home-review__item__name">“My first plant arrived perfectly packaged! The care instructions are super clear so hopefully I can keep it alive!”</p>
        <span class="home-review__item__name">-Margaret; NJ</span>
      </div>
    </div>
    <div class="home-review__card">
      <img class="home-review__open-quote" src="/assets/images/icons/open-quote.svg"/>
      <img class="home-review__close-quote" src="/assets/images/icons/open-quote.svg"/>
      <div class="home-review__item">
        <p class="home-review__item__name">“I've gotten two really nice plants from Hey Nice Plants so far, and they're both thriving! My apartment looks like a real adult lives here.”</p>
          <span class="home-review__item__name">-Julian; MN</span>
      </div>
    </div>
    <div class="home-review__card">
      <img class="home-review__open-quote" src="/assets/images/icons/open-quote.svg"/>
      <img class="home-review__close-quote" src="/assets/images/icons/open-quote.svg"/>
      <div class="home-review__item">
        <p class="home-review__item__name">“I don't really trust plants from Trader Joe's, they always seem to die on me. My first plant from HNP arrived really healthy and perked right up after its first watering!”</p>
          <span class="home-review__item__name">-Angel; NY</span>
      </div>
    </div>
    <div class="home-review__card">
      <img class="home-review__open-quote" src="/assets/images/icons/open-quote.svg"/>
      <img class="home-review__close-quote" src="/assets/images/icons/open-quote.svg"/>
      <div class="home-review__item">
        <p class="home-review__item__name">“As someone who doesn't know which plants are best for me and my space, I am obsessed with this subscription.”</p>
          <span class="home-review__item__name">-Heather; CO</span>
      </div>
    </div>
  </owl-carousel>
</section>

<section class="we-really-like-plants container">
  <div class="row">
    <div class="col-12 text-center">
      <h2 class="title title--primary">
        we really like plants
      </h2>
    </div>
  </div>
</section>


<section class="container-fluid read-our-blog">
  <div class="d-none d-lg-block">
    <div class="row">
      <div class="col-lg-3">
        <div class="read-our-blog__item" style="background-image: url('/assets/images/home/our-blog-1.jpg')">

        </div>
      </div>
      <div class="col-lg-2">
        <div class="row read-our-blog__item flex-column">
          <div class="col p-0 pb-3">
            <div class="read-our-blog__item__inside" style="background-image: url('/assets/images/home/our-blog-2.jpg')"></div>
          </div>
          <div class="col p-0">
            <div class="read-our-blog__item__inside read-our-blog__item__inside--text">
              <span>BLOG COMING SOON :)</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="read-our-blog__item" style="background-image: url('/assets/images/home/our-blog-3.jpg')">

        </div>
      </div>
      <div class="col-lg-4">
        <div class="read-our-blog__item" style="background-image: url('/assets/images/home/our-blog-4.jpg')">

        </div>
      </div>
    </div>
  </div>
  <owl-carousel class="d-lg-none" #owlMobile [options]="mobileSliderOptions" [carouselClasses]="['owl-custom-theme']">
    <div class="col-lg-3">
      <div class="read-our-blog__item" style="background-image: url('/assets/images/home/our-blog-1.jpg')">

      </div>
    </div>
    <div class="col-lg-2">
      <div class="row read-our-blog__item flex-column">
        <div class="col p-0 pb-3">
          <div class="read-our-blog__item__inside" style="background-image: url('/assets/images/home/our-blog-2.jpg')"></div>
        </div>
        <div class="col p-0">
          <div class="read-our-blog__item__inside read-our-blog__item__inside--text">
            <span>BLOG COMING SOON :)</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="read-our-blog__item" style="background-image: url('/assets/images/home/our-blog-3.jpg')">

      </div>
    </div>
    <div class="col-lg-4">
      <div class="read-our-blog__item" style="background-image: url('/assets/images/home/our-blog-4.jpg')">

      </div>
    </div>
  </owl-carousel>
</section>
