import { Provider, APP_BOOTSTRAP_LISTENER, ComponentRef, PLATFORM_ID, InjectionToken, Inject } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, NavigationCancel, NavigationError } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { AppService, FacebookPixelService } from '.';

export const NAV_INTERCEPTOR_PROVIDER: Provider = {
  provide: APP_BOOTSTRAP_LISTENER,
  multi: true,
  useFactory: NavigationInterceptorInitializer,
  deps: [
    FacebookPixelService,
    Router,
    AppService
  ]
};

export function NavigationInterceptorInitializer(
  $fpService: FacebookPixelService,
  $router: Router,
  $appService: AppService,
) {
  return async (c: ComponentRef<any>) => {
    $router.events
    .subscribe((event) => {
      if (event instanceof NavigationStart) {
        $appService.scrollToTop();
        $appService.setAppLoading(true);
        // console.log('loading');
      }
      if (event instanceof NavigationEnd) {
        $fpService.pageView();
        $appService.updateIntercom();
        // console.log('loaded');
      }
      // Set loading state to false in both of the below events to hide the spinner in case a request fails
      if (event instanceof NavigationCancel || event instanceof NavigationEnd || event instanceof NavigationError) {
        $appService.setAppLoading(false);
      }
    });
  };
}
