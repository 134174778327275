import { Injectable } from '@angular/core';

// Date utilities
import * as format from 'date-fns/format';
import * as addDays from 'date-fns/add_days';
import * as startOfDay from 'date-fns/start_of_day';
import * as getDay from 'date-fns/get_day';

import * as includes from 'lodash/includes';

@Injectable()
export class DatePickerService {

  disabledDates = [
    '01-01',
    '01-15',
    '02-19',
    '05-28',
    '07-04',
    '09-03',
    '10-08',
    '11-11',
    '11-12',
    '11-22',
    '12-25',
  ].map(date => (`${new Date().getFullYear()}-${date}`))

  disableDays = [0, 6];

  constructor() { }

  getNextAvailableDay(today = startOfDay(new Date())) {
    const dayOfWeek = getDay(today);
    const formatDate = format(today, 'YYYY-MM-DD');

    if (includes(this.disableDays, dayOfWeek) || includes(this.disabledDates, formatDate)) {
      return this.getNextAvailableDay(addDays(today, 1));
    }

    return today;
  }
}
