/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./free-shipping-lead-form.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "./free-shipping-lead-form.component";
import * as i5 from "@angular/router";
import * as i6 from "../../../../core/services/leads.service";
import * as i7 from "../../../../error/services/error.service";
import * as i8 from "ngx-webstorage";
var styles_FreeShippingLeadFormComponent = [i0.styles];
var RenderType_FreeShippingLeadFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FreeShippingLeadFormComponent, data: {} });
export { RenderType_FreeShippingLeadFormComponent as RenderType_FreeShippingLeadFormComponent };
function View_FreeShippingLeadFormComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "lead-error error-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please use a valid email"]))], null, null); }
function View_FreeShippingLeadFormComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "span", [["class", "user-suggested-email"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Do you mean "])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "strong", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveSuggestedEmail() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["? "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.suggestedEmail$)); _ck(_v, 3, 0, currVal_0); }); }
export function View_FreeShippingLeadFormComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 38, "div", [["class", "lead-banner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "a", [["class", "close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeLeadForm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "sr-only"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["close"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "p", [["class", "logo-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Hey Nice Plants"])), (_l()(), i1.ɵeld(7, 0, null, null, 29, "div", [["class", "lead-banner-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 7, "p", [["class", "lead-banner__title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "span", [["class", "text-bolder"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["WE'VE GOT SUCCS"])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" GET FREE SHIPPING ON "])), (_l()(), i1.ɵeld(13, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "span", [["class", "text-bolder"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["YOUR FIRST BOX!"])), (_l()(), i1.ɵeld(16, 0, null, null, 17, "form", [["class", "lead-banner__form"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 18).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 18).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(17, 16384, null, 0, i3.ɵangular_packages_forms_forms_bh, [], null, null), i1.ɵdid(18, 540672, null, 0, i3.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i3.ControlContainer, null, [i3.FormGroupDirective]), i1.ɵdid(20, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i1.ɵeld(21, 0, null, null, 10, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 7, "input", [["class", "lead-input form-group__input text-extralight"], ["formControlName", "email"], ["id", "nameInput"], ["placeholder", "Enter your email"], ["required", "required"], ["type", "email"]], [[1, "required", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 23).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 23)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 23)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(23, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(24, 16384, null, 0, i3.RequiredValidator, [], { required: [0, "required"] }, null), i1.ɵprd(1024, null, i3.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i3.RequiredValidator]), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(27, 671744, null, 0, i3.FormControlName, [[3, i3.ControlContainer], [6, i3.NG_VALIDATORS], [8, null], [6, i3.NG_VALUE_ACCESSOR], [2, i3.ɵangular_packages_forms_forms_k]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i3.NgControl, null, [i3.FormControlName]), i1.ɵdid(29, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FreeShippingLeadFormComponent_1)), i1.ɵdid(31, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(32, 0, null, null, 1, "button", [["class", "lead-banner__btn btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitLeadForm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" I want succulents "])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_FreeShippingLeadFormComponent_2)), i1.ɵdid(35, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(37, 0, null, null, 1, "a", [["class", "term-text"]], [[1, "test-id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeLeadForm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["No thanks, I don\u2019t want free shipping"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.form; _ck(_v, 18, 0, currVal_7); var currVal_16 = "required"; _ck(_v, 24, 0, currVal_16); var currVal_17 = "email"; _ck(_v, 27, 0, currVal_17); var currVal_18 = _co.isFieldInvalid("email", "invalidEmail"); _ck(_v, 31, 0, currVal_18); var currVal_19 = i1.ɵunv(_v, 35, 0, i1.ɵnov(_v, 36).transform(_co.showSuggestedEmail$)); _ck(_v, 35, 0, currVal_19); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 20).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 20).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 20).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 20).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 20).ngClassValid; var currVal_5 = i1.ɵnov(_v, 20).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 20).ngClassPending; _ck(_v, 16, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = (i1.ɵnov(_v, 24).required ? "" : null); var currVal_9 = i1.ɵnov(_v, 29).ngClassUntouched; var currVal_10 = i1.ɵnov(_v, 29).ngClassTouched; var currVal_11 = i1.ɵnov(_v, 29).ngClassPristine; var currVal_12 = i1.ɵnov(_v, 29).ngClassDirty; var currVal_13 = i1.ɵnov(_v, 29).ngClassValid; var currVal_14 = i1.ɵnov(_v, 29).ngClassInvalid; var currVal_15 = i1.ɵnov(_v, 29).ngClassPending; _ck(_v, 22, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_20 = "lead-banner__close-button"; _ck(_v, 37, 0, currVal_20); }); }
export function View_FreeShippingLeadFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sucstu-free-shipping-lead-form", [], null, null, null, View_FreeShippingLeadFormComponent_0, RenderType_FreeShippingLeadFormComponent)), i1.ɵdid(1, 114688, null, 0, i4.FreeShippingLeadFormComponent, [i5.ActivatedRoute, i6.LeadsService, i7.ErrorService, i8.LocalStorageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FreeShippingLeadFormComponentNgFactory = i1.ɵccf("sucstu-free-shipping-lead-form", i4.FreeShippingLeadFormComponent, View_FreeShippingLeadFormComponent_Host_0, {}, { close: "close", submit: "submit" }, []);
export { FreeShippingLeadFormComponentNgFactory as FreeShippingLeadFormComponentNgFactory };
