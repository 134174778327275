import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
// actions
import * as coupon from '@app/store/actions/coupon.actions';
// services
import { CouponsService } from '@app/core/services/coupons.service';
import * as fromPlans from '@app/store/selectors/plans.selector';
var CouponsEffects = /** @class */ (function () {
    function CouponsEffects(couponsService, actions$, store, sessionStorage, localStorageService) {
        var _this = this;
        this.couponsService = couponsService;
        this.actions$ = actions$;
        this.store = store;
        this.sessionStorage = sessionStorage;
        this.localStorageService = localStorageService;
        this.verifyCoupon$ = this.actions$
            .pipe(ofType(coupon.VERIFY_COUPON), map(function (action) { return action.payload; }), withLatestFrom(this.store.pipe(select(fromPlans.getSelectedPlanId))), switchMap(function (_a) {
            var couponCode = _a[0], planId = _a[1];
            return _this.couponsService.verifyCoupon(couponCode, planId)
                .pipe(map(function (couponObj) {
                _this.store.dispatch(new coupon.VerifyCouponCompleteAction(couponObj));
            }), catchError(function (reason) {
                _this.store.dispatch(new coupon.VerifyCouponFailAction(reason.errors));
                return of(null);
            }));
        }));
        this.verifyCouponComplete$ = this.actions$
            .pipe(ofType(coupon.VERIFY_COUPON_COMPLETE))
            .pipe(map(function (action) { return action.payload; }), tap(function (coupon) { return _this.sessionStorage.store('coupon', JSON.stringify(coupon)); }));
        this.clearCoupon$ = this.actions$
            .pipe(ofType(coupon.CLEAR_COUPON))
            .pipe(tap(function () {
            _this.localStorageService.clear('showCouponNotification');
            _this.sessionStorage.clear('coupon');
        }));
    }
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], CouponsEffects.prototype, "verifyCoupon$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], CouponsEffects.prototype, "verifyCouponComplete$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], CouponsEffects.prototype, "clearCoupon$", void 0);
    return CouponsEffects;
}());
export { CouponsEffects };
