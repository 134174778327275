import { ActivatedRoute } from '@angular/router';
import {Component, OnInit} from '@angular/core';
import { FormControl, FormGroup} from '@angular/forms';
import { LocalStorageService } from 'ngx-webstorage';
import { EmailValidator } from '@app/shared/validators/email.validator';

import { LeadsService } from '@app/core/services/leads.service';
import { LeadFormBaseComponent } from '../lead-form-base/lead-form-base.component';
import { ErrorService } from '@app/error';
import {debounceTime, distinctUntilChanged, filter, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'sucstu-five-off-lead-form',
  styleUrls: ['./five-off-lead-form.component.scss'],
  templateUrl: './five-off-lead-form.component.html'
})
export class FiveOffLeadFormComponent extends LeadFormBaseComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private leadService: LeadsService,
    private errorService: ErrorService,
    private localStorage: LocalStorageService,
  ) {
    super();
  }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', EmailValidator.checkEmail)
    });
    this.emailFormField = 'email';

    this.form.get('email').valueChanges
      .pipe(
        filter(data => typeof data === 'string' && data.indexOf('@') !== -1),
        debounceTime(1000),
        distinctUntilChanged(),
      )
      .pipe(takeUntil(this.destroyComponent$))
      .subscribe(email => {
        this.testSuggestedEmails(email);
      });
  }

  submitLeadForm() {
    const email = this.form.get('email').value;

    if (!email.length || this.isFieldInvalid('email', 'invalidEmail')) {
      return;
    }

    this.createLead(email)
      .then(() => {
        this.submit.emit({ email });
        this.closeLeadForm();
      })
      .catch(error => {
        this.closeLeadForm();
        const message = `Error trying to create/update Lead.`;
        this.errorService.client.notify(error, {
          beforeSend: report => {
            report.severity = 'warning';
            report.updateMetaData('extras', {
              http: true,
              client: true,
              message,
              error,
            });
          }
        });
      });
  }

  createLead(email: string = ''): Promise<any> {
    const uuid = this.localStorage.retrieve('uuid');
    const utmData = this.route.snapshot.queryParams;
    this.localStorage.store('customerEmail', email);

    return this.leadService
      .create({ uuid, email, utmData, leadForm: 'five-off' })
      .toPromise()
      .then(data => {
        return data;
      });
  }

  isFieldInvalid(field: string, validation: string) {
    const control = this.form.get(field);
    return control.hasError(validation) && control.touched;
  }
}
