import { AppConfig } from '@app/app.config';
import { isPlatformBrowser } from '@angular/common';
import { SessionStorageService } from 'ngx-webstorage';
import * as isToday from 'date-fns/is_today';
import * as format from 'date-fns/format';
import * as addDays from 'date-fns/add_days';
import * as i0 from "@angular/core";
import * as i1 from "../../app.config";
import * as i2 from "ngx-webstorage";
var ConvertionService = /** @class */ (function () {
    function ConvertionService(app_config, platformId, sessionStorageService) {
        this.app_config = app_config;
        this.platformId = platformId;
        this.sessionStorageService = sessionStorageService;
    }
    ConvertionService.prototype.reportSubscriptionSale = function (data) {
        var subscription = data.subscription, payment = data.payment, user = data.user, plan = data.plan, coupon = data.coupon;
        this.reportTransactionComplete(user, subscription, coupon, payment, plan);
        this.reportIre(subscription, user, coupon, plan);
        this.reportUetq(payment);
    };
    ConvertionService.prototype.reportOTBSale = function (_a) {
        var user = _a.user, payment = _a.payment, shipment = _a.shipment, box = _a.box;
        if (isPlatformBrowser(this.platformId) && 'dataLayer' in window) {
            var shippingCosts = box.shippingCosts;
            window.dataLayer.push({
                'event': 'transactionComplete',
                'transactionId': this.app_config.production && this.app_config.name === 'production'
                    ? shipment._id : "TEST_" + shipment._id,
                'transactionTotal': payment.amount,
                'transactionTax': payment.taxes,
                'transactionShipping': shippingCosts * payment.shipments.length,
                'transactionType': payment.transaction,
                'transactionProducts': [
                    {
                        'name': payment.transaction,
                        'sku': payment.transaction,
                        'price': box.price,
                        'quantity': payment.shipments.length
                    }
                ],
                'customerEmail': user.email,
            });
        }
    };
    ConvertionService.prototype.reportShipmentSale = function (_a) {
        var user = _a.user, subscription = _a.subscription, coupon = _a.coupon, payment = _a.payment, plan = _a.plan;
        if (isPlatformBrowser(this.platformId) && 'dataLayer' in window) {
            var utmData = this.sessionStorageService.retrieve('utmData');
            var affiliateRef = this.sessionStorageService.retrieve('affiliateRef');
            var shippingCosts = plan.shippingCosts;
            console.log('Affiliate Ref', affiliateRef);
            window.dataLayer.push({
                'event': 'transactionComplete',
                'transactionId': this.app_config.production && this.app_config.name === 'production'
                    ? subscription._id : "TEST_" + subscription._id,
                'transactionTotal': payment.amount,
                'transactionTax': payment.taxes,
                'transactionShipping': shippingCosts * payment.shipments.length,
                'transactionType': payment.transaction,
                'transactionProducts': [
                    {
                        'name': payment.transaction,
                        'sku': payment.transaction,
                        'price': plan.price,
                        'quantity': payment.shipments.length
                    }
                ],
                'customerEmail': user.email,
                'sourceParam': utmData && utmData.utm_source,
                'sourceAffiliateRef': affiliateRef,
                'coupon': coupon && coupon.code.toLowerCase()
            });
        }
    };
    ConvertionService.prototype.reportMonthlyRenewal = function (data, total) {
        if (isPlatformBrowser(this.platformId)) {
            if ('dataLayer' in window) {
                window.dataLayer.push({
                    'event': 'transactionComplete',
                    'transactionId': this.app_config.production && this.app_config.name === 'production'
                        ? data.subscription._id
                        : "TEST_" + data.subscription._id,
                    'transactionTotal': total,
                    transactionType: data.subscription.plan.type,
                    'transactionProducts': [
                        {
                            'name': data.subscription.plan.title,
                            'sku': data.subscription.plan.name,
                            'price': total,
                            'quantity': 1
                        }
                    ]
                });
            }
            this.reportIre(data.subscription, data.user, data.coupon, data.plan);
        }
    };
    ConvertionService.prototype.reportTransactionComplete = function (user, subscription, coupon, payment, plan) {
        if (isPlatformBrowser(this.platformId) && 'dataLayer' in window) {
            var utmData = this.sessionStorageService.retrieve('utmData');
            var affiliateRef = this.sessionStorageService.retrieve('affiliateRef');
            var shippingCosts = plan.shippingCosts;
            console.log('Affiliate Ref', affiliateRef);
            window.dataLayer.push({
                'event': 'transactionComplete',
                'transactionId': this.app_config.production && this.app_config.name === 'production'
                    ? subscription._id : "TEST_" + subscription._id,
                'transactionTotal': payment.amount,
                'transactionTax': payment.taxes,
                'transactionShipping': subscription.isPrepay ? shippingCosts * plan.term : shippingCosts * plan.shipmentsAtCheckout,
                'transactionType': plan.type,
                'transactionProducts': [
                    {
                        'name': plan.title,
                        'sku': plan.name,
                        'price': plan.price,
                        'quantity': subscription.isPrepay ? plan.term : 1
                    }
                ],
                'customerEmail': user.email,
                'sourceParam': utmData && utmData.utm_source,
                'sourceAffiliateRef': affiliateRef,
                'coupon': coupon && coupon.code.toLowerCase()
            });
        }
    };
    ConvertionService.prototype.reportIre = function (subscription, user, coupon, plan) {
        if (isPlatformBrowser(this.platformId) && window.ire) {
            window['ire']('identify', {
                customerId: user._id,
                customerEmail: user.email
            });
            window['ire']('trackConversion', 15273, {
                orderId: subscription._id,
                customerId: user._id,
                customerStatus: isToday(user.createdAt) ? 'new' : 'returning',
                orderPromoCode: coupon ? coupon.code.toLowerCase() : '',
                orderDiscount: coupon ? parseInt(coupon.amount, 10) : 0,
                currencyCode: 'USD',
                items: [
                    {
                        subTotal: plan.price,
                        category: plan.type.toLowerCase(),
                        sku: plan.name,
                        quantity: 1
                    }
                ]
            });
        }
    };
    ConvertionService.prototype.reportUetq = function (payment) {
        if (isPlatformBrowser(this.platformId) && window.uetq && window.uetq.push) {
            window['uetq'].push('event', 'checkout', {
                revenue_value: payment.amount,
                currency: 'USD'
            });
        }
    };
    ConvertionService.prototype.reportNewLead = function (email) {
        if (isPlatformBrowser(this.platformId) && this.app_config.production && this.app_config.name === 'production') {
            window['dataLayer'].push({
                'event': 'leadFormSubmited',
                'lead': {
                    'email': email
                }
            });
        }
    };
    ConvertionService.prototype.reportThanks = function (data, total, coupon) {
        this.reportTransactionComplete2(data, total, coupon);
        this.reportIre(data.subscription, data.user, data.coupon, data.plan);
        this.reportUetq({ amount: total });
        this.surveyOptIn(data);
    };
    ConvertionService.prototype.surveyOptIn = function (data) {
        if (isPlatformBrowser(this.platformId)) {
            if (window.gapi) {
                window.gapi.load('surveyoptin', function () {
                    window.gapi.surveyoptin.render({
                        // REQUIRED FIELDS
                        'merchant_id': 129373759,
                        'order_id': data.subscription._id,
                        'email': data.subscription.user.email,
                        'delivery_country': 'US',
                        'estimated_delivery_date': format(addDays(data.shipment.printed.cantPrintUntil, 7), 'YYYY-MM-DD'),
                    });
                });
            }
        }
    };
    ConvertionService.prototype.reportTransactionComplete2 = function (data, total, coupon) {
        if (isPlatformBrowser(this.platformId)) {
            if ('dataLayer' in window) {
                window.dataLayer.push({
                    'event': 'transactionComplete',
                    'transactionId': this.app_config.production && this.app_config.name === 'production'
                        ? data.subscription._id
                        : "TEST_" + data.subscription._id,
                    'transactionTotal': total,
                    transactionType: data.subscription.plan.type,
                    'transactionProducts': [
                        {
                            'name': data.subscription.plan.title,
                            'sku': data.subscription.plan.name,
                            'price': total,
                            'quantity': 1
                        }
                    ],
                    'customerEmail': data.subscription.user.email,
                    'sourceParam': data.subscription.user.utmData && data.subscription.user.utmData.utm_source,
                    'coupon': coupon && coupon.code.toLowerCase()
                });
            }
        }
    };
    ConvertionService.prototype.reportManualAddressFailed = function () {
        if (isPlatformBrowser(this.platformId) && 'dataLayer' in window) {
            window.dataLayer.push({
                'event': 'manuallyAddressFail',
                'manuallyAddressFail': true,
            });
        }
    };
    ConvertionService.prototype.reportGiftDone = function (data, total, coupon) {
        this.reportTransactionComplete2(data, total, coupon);
        this.reportUetq({ amount: total });
        this.surveyOptIn(data);
        this.reportIre(data.subscription, data.user, data.coupon, data.plan);
    };
    ConvertionService.prototype.setFullstoryUser = function (result) {
        if (isPlatformBrowser(this.platformId) && 'FS' in window) {
            console.log('Clear FullStory user.');
            window.FS.identify(result.user._id, {
                displayName: result.user.fullName,
                email: result.user.email
            });
        }
    };
    ConvertionService.prototype.clearFullstoryUser = function () {
        if (isPlatformBrowser(this.platformId) && 'FS' in window) {
            console.log('Clear FullStory user.');
            window.FS.identify(false);
        }
    };
    ConvertionService.prototype.setIdentify = function (result) {
        if (isPlatformBrowser(this.platformId) && '__insp' in window) {
            console.log('Set __insp.');
            window.__insp.push(['identify', result.user.email]);
        }
    };
    ConvertionService.prototype.clearIdentify = function () {
        if (isPlatformBrowser(this.platformId) && '__insp' in window) {
            console.log('Clear __insp.');
            window.__insp.push(['identify', null]);
        }
    };
    ConvertionService.ngInjectableDef = i0.defineInjectable({ factory: function ConvertionService_Factory() { return new ConvertionService(i0.inject(i1.APP_CONFIG), i0.inject(i0.PLATFORM_ID), i0.inject(i2.SessionStorageService)); }, token: ConvertionService, providedIn: "root" });
    return ConvertionService;
}());
export { ConvertionService };
