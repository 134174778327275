import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APP_CONFIG, AppConfig } from '@app/app.config';
import * as format from 'date-fns/format';
import * as addDays from 'date-fns/add_days';

import { Coupon } from '@app/core/models/coupon.model';
import * as isToday from 'date-fns/is_today';
import * as ThanksData from '@config/thanks.json';
import { isPlatformBrowser } from '@angular/common';
import { ConvertionService } from '@app/core/services/convertion.service';

@Component({
  selector: 'sucstu-thanks',
  styleUrls: ['./thanks.component.scss'],
  templateUrl: './thanks.component.html',
})
export class ThanksComponent implements OnInit {
  thanksData = (ThanksData as any).default;

  data: any = this.route.snapshot.data.data;

  constructor(
    private route: ActivatedRoute,
    private convertionService: ConvertionService) {
  }

  ngOnInit() {
    const coupon: Coupon = this.data.subscription.usedCoupons[0];
    const total = this.data.subscription.plan.price + (
      this.data.subscription.plan.shippingCosts * this.data.subscription.plan.shipmentsAtCheckout
    );
    this.convertionService.reportThanks(this.data, total, coupon);
  }
}
