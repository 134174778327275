/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./about.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/pipes/safe-html.pipe";
import * as i3 from "@angular/platform-browser";
import * as i4 from "./about.component";
import * as i5 from "../../../core/services/app.service";
var styles_AboutComponent = [i0.styles];
var RenderType_AboutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AboutComponent, data: {} });
export { RenderType_AboutComponent as RenderType_AboutComponent };
export function View_AboutComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.SafeHtmlPipe, [i3.DomSanitizer]), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "page-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "about-container container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h1", [["class", "row section__title about__title text-medium"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "row about__text-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "col-xl-9 col-lg-11 col-md-12 col-sm-11 col-10 about__text"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(7, 1), (_l()(), i1.ɵeld(8, 0, null, null, 3, "div", [["class", "container-xl"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "div", [["class", "row justify-content-center about-image-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "col-xl-10 col-lg-12 col-md-12 d-none d-sm-block about__image-ontop"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.aboutData == null) ? null : ((_co.aboutData.mainSection == null) ? null : _co.aboutData.mainSection.title)); _ck(_v, 4, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v, 0), ((_co.aboutData == null) ? null : ((_co.aboutData.mainSection == null) ? null : _co.aboutData.mainSection.content)))); _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "", ((_co.aboutData == null) ? null : ((_co.aboutData.mainSection == null) ? null : _co.aboutData.mainSection.image)), ""); _ck(_v, 11, 0, currVal_2); }); }
export function View_AboutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sucstu-pages-about", [], null, null, null, View_AboutComponent_0, RenderType_AboutComponent)), i1.ɵdid(1, 4308992, null, 0, i4.AboutComponent, [i3.Meta, i3.Title, i5.AppService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AboutComponentNgFactory = i1.ɵccf("sucstu-pages-about", i4.AboutComponent, View_AboutComponent_Host_0, {}, {}, []);
export { AboutComponentNgFactory as AboutComponentNgFactory };
