import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, AfterViewInit, Inject, PLATFORM_ID } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { EmailValidator } from './../../../shared/validators/email.validator';
import * as BrandData from "@config/brand.json";
import * as $ from 'jquery';

import { fromEvent } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';

@Component({
  selector: 'sucstu-lead-banner',
  styleUrls: ['./lead-banner.component.scss'],
  template: `
  <div class="lead-banner-container" #bannerContainer>
    <div class="lead-banner-background"></div>
    <button class="close-lead-button" (click)="closeBanner()">X</button>
    <div class="lead-banner" #banner>
      <div class="lead-banner-content container-fluid">
        <div class="row justify-content-center" style="height: 100%">
          <div class="col-12 text-center mt-auto">
            <h1 class="lead-banner__title text-bolder">get plants that like you</h1>
          </div>
          <div class="col-12 text-center mb-5">
            <h3 class="lead-banner__subtitle">and $5 off! *</h3>
          </div>

          <form class="col-12 text-center lead-banner__form" [formGroup]="form">
            <div class="form-group mb-5">
              <input
                class="lead-input text-extralight"
                id="nameInput" type="email"
                placeholder="Enter your email"
                formControlName="email"
                required="required" />
              <span class="lead-error error-text" *ngIf="isFieldInvalid('email', 'invalidEmail')">Please use a valid email</span>
            </div>
            <button
              class="get-deal-button button button--primary mb-5"
              type="button"
              (click)="submitEmail()">GET THE DEAL</button>

            <span class="d-block lead-banner__help-text">
              *Offer applies to the first month of new subscriptions only.
              <br>
              Cannot be combined with other offers.
            </span>
          </form>

          <div class="col-12 text-center mt-auto mb-5">
            <a [attr.test-id]="'lead-banner__close-button'" class="lead-banner__link"
               (click)="closeBanner()">No thanks, I don’t want a $5 discount.
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  `
})
export class LeadBannerComponent implements AfterViewInit {
  brandData = (BrandData as any).default;

  @ViewChild('banner') banner: ElementRef;
  @ViewChild('bannerContainer') bannerContainer: ElementRef;

  // @Input() parent: FormGroup;
  @Output() close: EventEmitter<any> = new EventEmitter<any>();
  @Output() submit: EventEmitter<string> = new EventEmitter<string>();

  form = this.fb.group({
    email: ['', EmailValidator.checkEmail]
  });

  constructor(
    private fb: FormBuilder,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      fromEvent(document, 'click')
        .pipe(
          map((e: MouseEvent) => `.${(e as any).target.classList[0]}`),
          filter((className: string) => !this.bannerContainer.nativeElement.querySelector(className)),
          filter((className: string) => {
            if (
              document.getElementById &&
              document.getElementById('intercom-container') &&
              document.getElementById('intercom-container').querySelector
            ) {
              return !document.getElementById('intercom-container').querySelector(className);
            } else {
              return true;
            }
          }),
        )
        .subscribe((className: string) => {
          this.closeBanner();
        });
    }
  }

  closeBanner() {
    if (isPlatformBrowser(this.platformId)) {
      $(document.body).removeClass('fixed-body');
    }
    this.close.emit();
  }

  submitEmail() {
    const email = this.form.get('email').value;
    if (email.length && !this.isFieldInvalid('email', 'invalidEmail')) {
      this.submit.emit(email);
      this.close.emit();
    }
  }

  isFieldInvalid(field: string, validation: string) {
    const control = this.form.get(field);
    return control.hasError(validation) && control.touched;
  }
}
