import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// config
import { APP_CONFIG, AppConfig } from '@app/app.config';
import { Address } from '@app/core/models/address.model';
import { tap } from 'rxjs/operators';
import { ConvertionService } from './convertion.service';

export interface LeadParams {
  uuid: string,
  email?: string,
  utmData?: any,
  leadForm?: string;
}

export interface WinItWednesdayParams extends LeadParams {
  social?: {
    instagram?: string;
  }
}

export interface LeadCheckoutParams {
  uuid: string,
  email: string,
  utmData?: any,
  plan: string,
  firstName: string,
  lastName: string,
  recipient: {
    firstName: string,
    lastName: string,
    email: string,
  },
  phone: string,
  address: Address,
  timeInSite: number,
  userIp: string,
  recaptchav3Token: string,
}

@Injectable()
export class LeadsService {

  constructor(
    private httpClient: HttpClient,
    private convertionService: ConvertionService,
    @Inject(APP_CONFIG) private app_config: AppConfig
  ) { }

  create({ uuid, email, utmData, leadForm }: LeadParams) {
    const headers = new HttpHeaders({ retry: 'yes' });
    return this.httpClient
      .post(`${this.app_config.api_uri}/leads`, { uuid, email, utmData, leadForm }, { headers })
      .pipe(tap(() => this.convertionService.reportNewLead(email)));
  }

  leadCheckout(data: LeadCheckoutParams) {
    return this.httpClient
      .post(`${this.app_config.api_uri}/leads/checkout`, data);
  }

  createNewWinItWednesdayEntry(data: WinItWednesdayParams) {
    return this.httpClient
      .post(`${this.app_config.api_uri}/win-it-wednesday`, data)
      .pipe(tap(() => this.convertionService.reportNewLead(data.email)));
  }
}
