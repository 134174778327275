import { isPlatformBrowser } from '@angular/common';
import * as i0 from "@angular/core";
var FacebookPixelService = /** @class */ (function () {
    function FacebookPixelService(platformId) {
        this.platformId = platformId;
    }
    FacebookPixelService.prototype.pageView = function () {
        if (isPlatformBrowser(this.platformId) && 'fbq' in window) {
            window.fbq('track', 'PageView');
        }
    };
    FacebookPixelService.ngInjectableDef = i0.defineInjectable({ factory: function FacebookPixelService_Factory() { return new FacebookPixelService(i0.inject(i0.PLATFORM_ID)); }, token: FacebookPixelService, providedIn: "root" });
    return FacebookPixelService;
}());
export { FacebookPixelService };
