
    <footer class="container-fluid" *ngIf="!isSales" [ngClass]="{'footer--alternative': alternativeFooter}">
      <div class="row">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col">
              <form [formGroup]="form" class="row" (ngSubmit)="createLead()">
                <div class="col-auto align-center--mobile footer-input-wrapper">
                  <input
                    class="footer-input"
                    type="email"
                    placeholder="Your email address"
                    formControlName="email"
                    required="required"/>
                  <span class="footer-lead-error" *ngIf="isFieldInvalid('email', 'invalidEmail')">
                    Please use a valid email
                  </span>
                </div>
                <div class="col-auto align-self-end align-center--mobile subscribe-button-wrapper">
                  <button [disabled]="!form.valid" class="button button--smaller" type="submit"
                          [ngClass]="{'button--white': !alternativeFooter, 'button--primary': alternativeFooter}">
                    GET OFFERS
                  </button>
                </div>
              </form>
            </div>

            <div class="col-auto align-center--mobile">
              <div class="row">
                <div class="col-auto mr-3">
                  <span routerLink="/about" class="footer-links d-block mb-3">about</span>
                  <a
                    *ngIf="brandData?.socialMedia?.facebook"
                    class="social-link__item"
                    [href]="brandData.socialMedia.facebook"
                    target="a_blank">
                    <img src="/assets/images/icons/social-media/facebook.svg" class="footer-social-media-icon" alt="Facebook Icon">
                  </a>
                </div>
                <div class="col-auto mr-3">
                  <span routerLink="/faq" class="footer-links d-block mb-3">faq</span>
                  <a
                    *ngIf="brandData?.socialMedia?.instagram"
                    class="social-link__item"
                    [href]="brandData.socialMedia.instagram"
                    target="a_blank">
                    <img src="/assets/images/icons/social-media/instagram.svg" class="footer-social-media-icon" alt="Instagram Icon">
                  </a>
                </div>
                <div class="col-auto mr-3">
                  <span routerLink="/gift" class="footer-links d-block mb-3">gift</span>
                  <a
                    *ngIf="brandData?.socialMedia?.pinterest"
                    class="social-link__item"
                    [href]="brandData.socialMedia.pinterest"
                    target="a_blank">
                    <img src="/assets/images/icons/social-media/pinterest-light.svg" class="footer-social-media-icon" alt="Pinterest Icon">
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  