import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import * as AboutData from '@config/about.json'
import * as MetaTagsData from '@config/metatags.json'
import * as TitleTags from '@config/titletags.json'
import { AppService } from '@app/core/services';

@Component({
  selector: 'sucstu-pages-about',
  styleUrls: ['./about.component.scss'],
  templateUrl: './about.template.html'
})

export class AboutComponent implements OnInit, AfterViewInit {
  aboutData = (AboutData as any).default;
  metaTagsData = (MetaTagsData as any).default;
  titleTagsData = (TitleTags as any).default;

  constructor(
    private metaService: Meta,
    private titleService: Title,
    private appService: AppService,
  ) { }

  ngOnInit() {
    this.metaService.updateTag({
      name: 'description',
      content: this.metaTagsData.about
    });

    this.titleService.setTitle(this.titleTagsData.about);
  }

  ngAfterViewInit(): void {
    this.appService.scrollToTop();
  }
}
