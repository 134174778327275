<div class="faq-question-container mb-2" [ngClass]="{ 'show-cursor': !open }">
  <div class="header" (click)="onToggle()">
    <div class="green-dot"></div>
    <ng-content select=".question__title"></ng-content>

    <span class="arrow" [class.flip-arrow]="open" style="margin-left: auto;">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10">
        <g fill="transparent" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" transform="translate(-1155 -28)">
          <path stroke="#f37359" stroke-width="3" d="M0 0L6 6 12 0" transform="translate(1157 30)"/>
        </g>
      </svg>
    </span>

  </div>
  <div class="body" [style.display]="open ? 'block' : 'none'">
    <ng-content select=".question__answer"></ng-content>
  </div>
</div>
