import * as tslib_1 from "tslib";
import { NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
var GoogleOptimizeService = /** @class */ (function () {
    function GoogleOptimizeService(zone, platformId) {
        this.zone = zone;
        this.platformId = platformId;
        this._isGoogleOptimizeInitialized$ = new BehaviorSubject(false);
        this.isGoogleOptimizeInitialized$ = this._isGoogleOptimizeInitialized$.asObservable().pipe(distinctUntilChanged());
        this._experiments$ = new BehaviorSubject({});
        this.experiments$ = this._experiments$.asObservable().pipe(distinctUntilChanged());
    }
    GoogleOptimizeService.prototype.setGoogleOptimizeAsInitialzed = function () {
        this._isGoogleOptimizeInitialized$.next(true);
    };
    GoogleOptimizeService.prototype.setExperiment = function (experiment) {
        var _a;
        var value = this._experiments$.getValue();
        this._experiments$.next(tslib_1.__assign({}, value, (_a = {}, _a[experiment.id] = experiment, _a)));
    };
    GoogleOptimizeService.prototype.setGoExperiments = function () {
        var _this = this;
        if (isPlatformBrowser(this.platformId)) {
            window['setGOExperiments'] = {
                zone: this.zone,
                componentFn: function (experiment) {
                    _this.setGoogleOptimizeAsInitialzed();
                    _this.setExperiment(experiment);
                    // RUN THIS FUNCTION ON OPTIMIZE OR IN YOUR CONSOLE TO ACTIVE the METHOD ABOVE
                    /*
                        window['setGOExperiments'].zone.run(() => {
                            window['setGOExperiments'].componentFn({ name: 'ccr_version', value: true, version: 1, reload: true })
                        });
                    */
                },
                component: this,
            };
        }
    };
    GoogleOptimizeService.prototype.optimizeActivate = function () {
        if (isPlatformBrowser(this.platformId)) {
            setTimeout(function () {
                if (window.dataLayer && typeof window.dataLayer.push === 'function') {
                    window.dataLayer.push({
                        event: 'optimize.activate',
                        eventCallback: function (containerId) {
                            var args = [];
                            for (var _i = 1; _i < arguments.length; _i++) {
                                args[_i - 1] = arguments[_i];
                            }
                            console.log('Container ID: ' + containerId, args);
                        }
                    });
                }
            }, 0);
        }
    };
    return GoogleOptimizeService;
}());
export { GoogleOptimizeService };
