
    <div class="app-loading" *ngIf="(appLoading$ | async) || !isBrowser">
      <div class="logo"></div>
      <svg class="spinner" viewBox="25 25 50 50">
        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
      </svg>
    </div>
    <div
      class="content">
      <!-- [@appState]="state" -->
      <div class="main">

        <sucstu-notification-bar
          [data]="(notificationBar$ | async)"
          (close)="onCloseNotificationBar()">
        </sucstu-notification-bar>

        <sucstu-coupon-banner
          [showBanner]="showCouponBanner"
          [coupon]="coupon$ | async"
          (close)="onCloseCouponBanner($event)">
        </sucstu-coupon-banner>

        <sucstu-header
          [moveFromTop]="notificationBarHeight"
          [ngClass]="{ 'moveByBanner': showCouponBanner || (notificationBar$ | async).open }">
        </sucstu-header>

        <div class="app-container">
          <router-outlet (deactivate)="onDeactivate()"></router-outlet>
        </div>

      </div>
      <sucstu-footer class="footer-container"></sucstu-footer>
    </div>
  