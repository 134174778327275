<div class="page-container">
  <div class="hero-picture-container">
    <img src="/assets/images/msg-mobile2.jpg" alt="Hero banner" class="hero-picture-mobile" />
    <img src="/assets/images/msg-desktop2.jpg" alt="Hero banner" class="hero-picture-desk" />
    <div class="hero-picture-content">
      <h1 class="hero-title">My gift to me!</h1>
      <div class="heart-row">
        <span class="heart-line"></span>
        <img class="heart-black-icon" src="/assets/images/icon-transparent@3x.png" alt="">
        <span class="heart-line"></span>
      </div>
      <p class="hero-text">
        Claim your succulent subscription and get
        <span>$5 OFF</span> your first month!
      </p>
    </div>
  </div>
  <section class="page-body">

    <div class="plan-description-container">
      <div class="plan-image">
        <img src="/assets/images/2x-plan.jpg" alt="2x plan picture" />
      </div>
      <div class="plan-description-text">
        <h2>1 plant / month</h2>
        <h4>$19 (+shipping)</h4>
        <p>Get our adorable, low-maintenance surprise plants for your home, office, business, dorm, or studio... anywhere
          you need a pop of color and boost of oxygen!</p>
        <p>Organically grown. Securely shipped. The best subscription box you'll receive all month
          <img src="/assets/images/heart-black.png" alt="">
        </p>
      </div>
    </div>

    <div class="claim-form-container container">
      <h2 class="claim-form-title">Enter your code*
        <span *ngIf="!isAuthenticated">+ email</span> to claim.</h2>
      <div class="claim-form-row">
        <form [formGroup]="form" class="claim-form">
          <div class="form-group form-group__flex-start">
            <input class="form-group__input claim-code-input" type="text" placeholder="Claim Code" formControlName="claimCode" name="claim-code">
          </div>
          <div class="form-group form-group__flex-start" *ngIf="!isAuthenticated">
            <input class="form-group__input email-input" type="email" placeholder="Email" formControlName="email" name="email" autocomplete="email">
            <p *ngIf="userExist" class="login-text">Email address already exist -
              <span (click)="goToLogin()">Log in here.</span>
            </p>
          </div>
          <a class="claim-button" (click)="claimSubscription()">Claim</a>
          <span class="claim-terms-text">*Code located on shipping label</span>
        </form>
        <div class="label-picture-container">
          <img src="/assets/images/claim-code-instructions.jpg" alt="Label picture with Claim code">
        </div>
      </div>
    </div>

    <div class="aside-section">
      <div class="gift-with-calendar-picture-container">
        <img class="gift-with-calendar-mobile" src="/assets/images/gift-with-calendar-mobile.jpg" alt="">
        <img class="gift-with-calendar-desk" src="/assets/images/gift-with-calendar-desk.png" alt="">
      </div>
      <div class="aside-text-container">
        <h4 class="aside-title">Subscribers get monthly SKIP + GIFT options</h4>
        <p class="aside-text"><span>SKIP</span> any month at no cost. </p>
        <p class="aside-text"><span>GIFT </span> any month to a friend.</p>
      </div>
    </div>

    <div class="attributes-section">

      <div class="attribute__item">
        <div class="attribute__picture-circle">
          <img src="/assets/images/jar.png" alt="Jar icon" />
        </div>
        <h3 class="attribute__title">Drought-tolerant</h3>
        <p class="attribute__text">Conserve water! Your plants only need a drink every 7-10 days.</p>
      </div>

      <div class="attribute__item">
        <div class="attribute__picture-circle">
          <img src="/assets/images/sun.png" alt="Sun icon" />
        </div>
        <h3 class="attribute__title">Easy Care</h3>
        <p class="attribute__text">Set in a spot that gets about 6+ hours of natural light a day.</p>
      </div>

      <div class="attribute__item">
        <div class="attribute__picture-circle">
          <img src="/assets/images/oxygen.png" alt="Oxygen icon" />
        </div>
        <h3 class="attribute__title">Oxygen-boosters</h3>
        <p class="attribute__text">Plants release oxygen all night long while your other plants release carbon dioxide.</p>
      </div>

      <div class="attribute__item">
        <div class="attribute__picture-circle">
          <img src="/assets/images/leaf.png" alt="Leaf icon" />
        </div>
        <h3 class="attribute__title">Low Pollen Production</h3>
        <p class="attribute__text">Most plants and all cacti are a 1 on the Ogren Plant Allergy Scale (OPALS), so they make perfect houseplants
          for those who suffer from allergies.</p>
      </div>

    </div>

  </section>
</div>
