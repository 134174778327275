/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./faq-question.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./faq-question.component";
var styles_FaqQuestionComponent = [i0.styles];
var RenderType_FaqQuestionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FaqQuestionComponent, data: {} });
export { RenderType_FaqQuestionComponent as RenderType_FaqQuestionComponent };
export function View_FaqQuestionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "faq-question-container mb-2"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "show-cursor": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "header"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onToggle() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "green-dot"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵeld(6, 0, null, null, 3, "span", [["class", "arrow"], ["style", "margin-left: auto;"]], [[2, "flip-arrow", null]], null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, ":svg:svg", [["height", "10"], ["viewBox", "0 0 16 10"], ["width", "16"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, ":svg:g", [["fill", "transparent"], ["fill-rule", "evenodd"], ["stroke-linecap", "round"], ["stroke-linejoin", "round"], ["transform", "translate(-1155 -28)"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, ":svg:path", [["d", "M0 0L6 6 12 0"], ["stroke", "#f37359"], ["stroke-width", "3"], ["transform", "translate(1157 30)"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "body"]], [[4, "display", null]], null, null, null, null)), i1.ɵncd(null, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "faq-question-container mb-2"; var currVal_1 = _ck(_v, 2, 0, !_co.open); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.open; _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.open ? "block" : "none"); _ck(_v, 10, 0, currVal_3); }); }
export function View_FaqQuestionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sucstu-faq-question", [], null, null, null, View_FaqQuestionComponent_0, RenderType_FaqQuestionComponent)), i1.ɵdid(1, 49152, null, 0, i3.FaqQuestionComponent, [], null, null)], null, null); }
var FaqQuestionComponentNgFactory = i1.ɵccf("sucstu-faq-question", i3.FaqQuestionComponent, View_FaqQuestionComponent_Host_0, {}, {}, [".question__title", ".question__answer"]);
export { FaqQuestionComponentNgFactory as FaqQuestionComponentNgFactory };
