import { isPlatformBrowser, Location } from '@angular/common';
import * as BrandData from '@config/brand.json';
import * as FooterData from '@config/footer.json';
import * as CarecardsData from '@config/carecards.json';
import * as ModulesData from '@config/modules.json';
import { LocalStorageService } from 'ngx-webstorage';
import * as fromServices from './../../../core/services';
import { AppConfig } from '@app/app.config';
import { ActivatedRoute } from '@angular/router';
import { ErrorService } from './../../../error';
import { FormBuilder } from '@angular/forms';
import { EmailValidator } from './../../../shared/validators/email.validator';
var FooterComponent = /** @class */ (function () {
    function FooterComponent(errorService, fb, locationService, localStorage, leadService, route, app_config, platformId) {
        this.errorService = errorService;
        this.fb = fb;
        this.locationService = locationService;
        this.localStorage = localStorage;
        this.leadService = leadService;
        this.route = route;
        this.app_config = app_config;
        this.platformId = platformId;
        this.footerData = FooterData.default;
        this.brandData = BrandData.default;
        this.carecardsData = CarecardsData.default;
        this.modulesData = ModulesData.default;
        this.form = this.fb.group({
            email: ['', EmailValidator.checkEmail]
        });
    }
    Object.defineProperty(FooterComponent.prototype, "isSales", {
        get: function () {
            return this.locationService.path() === '/sales' || this.locationService.path() === '/churn';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FooterComponent.prototype, "alternativeFooter", {
        get: function () {
            return this.locationService.path().includes('/checkout') ||
                this.locationService.path().includes('/faq') ||
                this.locationService.path() === '/gift' ||
                this.locationService.path().includes('/quiz');
        },
        enumerable: true,
        configurable: true
    });
    FooterComponent.prototype.moveToTop = function () {
        if (isPlatformBrowser(this.platformId)) {
            window.scroll(0, 0);
        }
    };
    FooterComponent.prototype.createLead = function () {
        var _this = this;
        var uuid = this.localStorage.retrieve('uuid');
        var utmData = this.route.snapshot.queryParams;
        var email = this.form.get('email').value;
        this.localStorage.store('customerEmail', email);
        console.log(uuid, utmData, email);
        this.leadService
            .create({ uuid: uuid, email: email, utmData: utmData })
            .subscribe(function () {
            _this.form.reset();
        }, function (error) {
            var message = "Error trying to create/update Lead.";
            _this.errorService.client.notify(error, {
                beforeSend: function (report) {
                    report.severity = 'warning';
                    report.updateMetaData('extras', {
                        http: true,
                        client: true,
                        message: message,
                        error: error,
                    });
                }
            });
        });
    };
    FooterComponent.prototype.isFieldInvalid = function (field, validation) {
        var control = this.form.get(field);
        return control.hasError(validation) && control.touched;
    };
    return FooterComponent;
}());
export { FooterComponent };
