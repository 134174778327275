<div class="page-container">
  <section class="faq-container container">
    <div class="row">
      <div class="col-12 text-center">
        <h1 *ngIf="faqData?.title" class="section__title faq__title text-medium">{{ faqData.title }}</h1>
        <h5 *ngIf="faqData?.firstText">{{ faqData?.firstText }}</h5>
        <h6 [innerHTML]="faqData?.secondText | safeHtml"></h6>
      </div>

      <div class="col-12 col-sm-10 offset-sm-1">
        <div class="questions-container">
          <sucstu-faq-question *ngFor="let qa of faqData?.questionsList">
            <h3 class="question__title" [innerHTML]="qa.title | safeHtml"></h3>
            <div class="question__answer question__answer-content" [innerHTML]="qa.answer | safeHtml"></div>
          </sucstu-faq-question>
        </div>
      </div>
    </div>
  </section>
</div>
