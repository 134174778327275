import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { APP_CONFIG, AppConfig } from '@app/app.config';
import * as BrandData from '@config/brand.json'
import * as GiftDoneData from '@config/gift-done.json'

import { Coupon } from '@app/core/models/coupon.model';
import { Store } from '@ngrx/store';
// services
import { SessionStorageService } from 'ngx-webstorage';

import { map } from 'rxjs/operators';
import { PlansService } from '@app/core/services';

import * as fromCouponsActions from './../../../store/actions/coupon.actions';
import { HttpParams } from '@angular/common/http';
import { ConvertionService } from '@app/core/services/convertion.service';

@Component({
  selector: 'sucstu-pages-gift-done',
  styleUrls: ['./gift-done.component.scss'],
  templateUrl: 'gift-done.template.html'
})
export class GiftDoneComponent implements OnInit {
  giftDoneData = (GiftDoneData as any).default;
  brandData = (BrandData as any).default;

  data: any = this.route.snapshot.data.data;

  renderBradsDealsPixel = false;
  bradsDealsTrafficData: { orderId: string, transactionId: string } = null;
  bradsDealsPixelTagUrl = '';

  constructor(
    @Inject(APP_CONFIG) private app_config: AppConfig,
    private route: ActivatedRoute,
    private plansService: PlansService,
    private sessionStorage: SessionStorageService,
    private store: Store<any>,
    private router: Router,
    private convertionService: ConvertionService,
  ) {
  }

  get isPlanABradsDealsDeal() {
    const { subscription } = this.data;
    return subscription.plan._id === this.plansService.BRADS_GIFT_BOGO;
  }

  get isProd() {
    return this.app_config.production && this.app_config.name === 'production';
  }

  ngOnInit() {
    const coupon: Coupon = this.data.subscription.usedCoupons[0];

    const total = this.data.subscription.plan.price + (
      this.data.subscription.plan.shippingCosts * this.data.subscription.plan.shipmentsAtCheckout
    );

    if (this.isPlanABradsDealsDeal) {
      this.plansService.getPlansStatistics()
        .pipe(
          map((plans) => plans.find(plan => plan._id === this.plansService.BRADS_GIFT_BOGO)),
          map((plan) => plan.total),
        )
        .subscribe((num: number) => {
          if (num > 500) {
            this.renderBradsDealsPixel = false;
            return;
          }
          this.renderBradsDealsPixel = true;
          const data = this.parseBradsDealsTrafficData();
          if (!data) {
            this.renderBradsDealsPixel = false;
            return;
          }

          const params = new HttpParams({
            fromObject: {
              nid: '104',
              oid: data.orderId,
              transaction_id: data.transactionId,
              amount: total.toString().replace('.', ',')
            }
          });

          this.bradsDealsPixelTagUrl = `https://bradsdeals.servtrk.com/?${params.toString()}`;
        });
    }

    this.convertionService.reportGiftDone(this.data, total, coupon);
  }

  parseBradsDealsTrafficData(): { orderId: string, transactionId: string } {
    const data = this.sessionStorage.retrieve('bradsDealsTrafficData');
    if (data) {
      return JSON.parse(data);
    }
    return null;
  }

  goToCheckoutWithCoupon() {
    this.store.dispatch(new fromCouponsActions.VerifyCouponAction('fivedollars'));
    this.router.navigate(['/checkout'], { queryParams: { planId: '5997c3ac8852a761249342e9' } });
  }
}
