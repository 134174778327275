/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./plan-card.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./plan-card.component";
var styles_PlanCardComponent = [i0.styles];
var RenderType_PlanCardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PlanCardComponent, data: {} });
export { RenderType_PlanCardComponent as RenderType_PlanCardComponent };
function View_PlanCardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["alt", "Most Popular sucstu plan"], ["class", "most-popular-image"], ["src", "/assets/images/mp-succs.png"]], null, null, null, null, null))], null, null); }
function View_PlanCardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "plan-card-badge"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_PlanCardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "plan-card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 15, "a", [["class", "plan-card-link"], ["routerLink", "/gift/quiz/intro"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlanCardComponent_1)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlanCardComponent_2)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "plan-card-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "plan-card-picture"]], null, null, null, null, null)), i1.ɵncd(null, 1), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "plan-card-title-container d-flex flex-column justify-content-center align-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "div", [["class", "plan-card-title"]], null, null, null, null, null)), i1.ɵncd(null, 2), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "plan-card-subtitle"]], null, null, null, null, null)), i1.ɵncd(null, 3), (_l()(), i1.ɵeld(15, 0, null, null, 1, "div", [["class", "plan-card-bottom-desc"]], null, null, null, null, null)), i1.ɵncd(null, 4)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.queryParams; var currVal_3 = "/gift/quiz/intro"; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_4 = _co.popular; _ck(_v, 4, 0, currVal_4); var currVal_5 = _co.badge; _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_PlanCardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sucstu-plan-card", [], null, null, null, View_PlanCardComponent_0, RenderType_PlanCardComponent)), i1.ɵdid(1, 114688, null, 0, i4.PlanCardComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PlanCardComponentNgFactory = i1.ɵccf("sucstu-plan-card", i4.PlanCardComponent, View_PlanCardComponent_Host_0, { planId: "planId", queryParams: "queryParams", popular: "popular", badge: "badge" }, {}, [".plan-card-badge", ".plan-card-picture-img", ".plan-card-title-text", ".plan-card-subtitle", ".plan-card-bottom"]);
export { PlanCardComponentNgFactory as PlanCardComponentNgFactory };
