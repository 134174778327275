import { NgModule } from '@angular/core';
// modules
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';


import * as fromPipes from './pipes';
import * as fromComponents from './components';
import * as fromDirectives from './directives';

export const DECLARATIONS = [
  ...fromComponents.components,
  ...fromDirectives.directives,
  ...fromPipes.pipes,
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  declarations: [...DECLARATIONS ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    ...DECLARATIONS
  ],
  entryComponents: [
    ...fromComponents.leadForms,
    fromComponents.LeadBannerComponent,
    fromComponents.NotificationBarComponent,
  ]
})
export class SharedModule {
}
