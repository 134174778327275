<div class="page-container">
  <div class="about-container container">
    <h1 class="row section__title about__title text-medium">{{aboutData?.mainSection?.title}}</h1>
    <div class="row about__text-container">
      <div
      class="col-xl-9 col-lg-11 col-md-12 col-sm-11 col-10 about__text" [innerHTML]="aboutData?.mainSection?.content | safeHtml"></div>
    </div>
  </div>
  <div class="container-xl">
    <div class="row justify-content-center about-image-container">
      <div class="col-xl-10 col-lg-12 col-md-12 d-none d-sm-block about__image-ontop">
        <img src="{{aboutData?.mainSection?.image}}">
      </div>
    </div>
  </div>
</div>
