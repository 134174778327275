import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { APP_CONFIG, AppConfig } from '@app/app.config';
import { isPlatformBrowser } from '@angular/common';
import { SessionStorageService } from 'ngx-webstorage';
import * as isToday from 'date-fns/is_today';

import { Payment } from '../models/payment.model';
import { Plan } from '@app/core/models/plan.model';
import { User } from '@app/core/models/user.model';
import { Coupon } from '@app/core/models/coupon.model';
import { Subscription } from '@app/core/models/subscription.model';

import * as format from 'date-fns/format';
import * as addDays from 'date-fns/add_days';
@Injectable({providedIn: 'root'})
export class ConvertionService {
  constructor(
    @Inject(APP_CONFIG) private app_config: AppConfig,
    @Inject(PLATFORM_ID) private platformId: any,
    private sessionStorageService: SessionStorageService,
  ) { }

  reportSubscriptionSale(data: any) {
    const { subscription, payment, user, plan, coupon } = data;
    this.reportTransactionComplete(user, subscription, coupon, payment, plan);
    this.reportIre(subscription, user, coupon, plan);
    this.reportUetq(payment);
  }

  reportOTBSale({ user, payment, shipment, box }) {
    if (isPlatformBrowser(this.platformId) && 'dataLayer' in window) {
      const shippingCosts = box.shippingCosts;
      (window as any).dataLayer.push({
        'event': 'transactionComplete',
        'transactionId': this.app_config.production && this.app_config.name === 'production'
          ? shipment._id : `TEST_${shipment._id}`,
        'transactionTotal': payment.amount,
        'transactionTax': payment.taxes,
        'transactionShipping': shippingCosts * payment.shipments.length,
        'transactionType': payment.transaction,
        'transactionProducts': [
          {
            'name': payment.transaction,
            'sku': payment.transaction,
            'price': box.price,
            'quantity': payment.shipments.length
          }
        ],
        'customerEmail': user.email,
      });
    }
  }

  reportShipmentSale({ user, subscription, coupon, payment, plan }) {
    if (isPlatformBrowser(this.platformId) && 'dataLayer' in window) {

      const utmData = this.sessionStorageService.retrieve('utmData');
      const affiliateRef = this.sessionStorageService.retrieve('affiliateRef');
      const shippingCosts = plan.shippingCosts;

      console.log('Affiliate Ref', affiliateRef);
      (window as any).dataLayer.push({
        'event': 'transactionComplete',
        'transactionId': this.app_config.production && this.app_config.name === 'production'
          ? subscription._id : `TEST_${subscription._id}`,
        'transactionTotal': payment.amount,
        'transactionTax': payment.taxes,
        'transactionShipping': shippingCosts * payment.shipments.length,
        'transactionType': payment.transaction,
        'transactionProducts': [
          {
            'name': payment.transaction,
            'sku': payment.transaction,
            'price': plan.price,
            'quantity': payment.shipments.length
          }
        ],
        'customerEmail': user.email,
        'sourceParam': utmData && utmData.utm_source,
        'sourceAffiliateRef': affiliateRef,
        'coupon': coupon && coupon.code.toLowerCase()
      });
    }
  }

  reportMonthlyRenewal(data: any, total) {
    if (isPlatformBrowser(this.platformId)) {
      if ('dataLayer' in window) {
        (window as any).dataLayer.push({
          'event': 'transactionComplete',
          'transactionId': this.app_config.production && this.app_config.name === 'production'
            ? data.subscription._id
            : `TEST_${data.subscription._id}`,
          'transactionTotal': total,
          transactionType: data.subscription.plan.type,
          'transactionProducts': [
            {
              'name': data.subscription.plan.title,
              'sku': data.subscription.plan.name,
              'price': total,
              'quantity': 1
            }
          ]
        });
      }
      this.reportIre(data.subscription, data.user, data.coupon, data.plan);
    }
  }

  private reportTransactionComplete(user, subscription: Subscription, coupon: Coupon, payment: Payment, plan: Plan) {
    if (isPlatformBrowser(this.platformId) && 'dataLayer' in window) {

      const utmData = this.sessionStorageService.retrieve('utmData');
      const affiliateRef = this.sessionStorageService.retrieve('affiliateRef');
      const shippingCosts = plan.shippingCosts;

      console.log('Affiliate Ref', affiliateRef);
      (window as any).dataLayer.push({
        'event': 'transactionComplete',
        'transactionId': this.app_config.production && this.app_config.name === 'production'
          ? subscription._id : `TEST_${subscription._id}`,
        'transactionTotal': payment.amount,
        'transactionTax': payment.taxes,
        'transactionShipping': subscription.isPrepay ? shippingCosts * (plan.term as any) : shippingCosts * plan.shipmentsAtCheckout,
        'transactionType': plan.type,
        'transactionProducts': [
          {
            'name': plan.title,
            'sku': plan.name,
            'price': plan.price,
            'quantity': subscription.isPrepay ? plan.term : 1
          }
        ],
        'customerEmail': user.email,
        'sourceParam': utmData && utmData.utm_source,
        'sourceAffiliateRef': affiliateRef,
        'coupon': coupon && coupon.code.toLowerCase()
      });
    }
  }

  private reportIre(subscription: Subscription, user: User, coupon: Coupon, plan: Plan) {
    if (isPlatformBrowser(this.platformId) && (window as any).ire) {
      window['ire']('identify', {
        customerId: user._id,
        customerEmail: user.email
      });

      window['ire']('trackConversion', 15273, {
        orderId: subscription._id,
        customerId: user._id,
        customerStatus: isToday(user.createdAt) ? 'new' : 'returning',
        orderPromoCode: coupon ? coupon.code.toLowerCase() : '',
        orderDiscount: coupon ? parseInt(coupon.amount as any, 10) : 0,
        currencyCode: 'USD',
        items: [
          {
            subTotal: plan.price,
            category: (plan.type as string).toLowerCase(),
            sku: plan.name,
            quantity: 1
          }
        ]
      });
    }
  }

  private reportUetq(payment: Payment|{amount: number}) {
    if (isPlatformBrowser(this.platformId) && (window as any).uetq && (window as any).uetq.push) {
      window['uetq'].push('event', 'checkout', {
        revenue_value: payment.amount,
        currency: 'USD'
      });
    }
  }

  reportNewLead(email){
    if (isPlatformBrowser(this.platformId) && this.app_config.production && this.app_config.name === 'production') {
      window['dataLayer'].push({
        'event': 'leadFormSubmited',
        'lead': {
          'email': email
        }
      });
    }
  }

  reportThanks(data: any, total, coupon) {
    this.reportTransactionComplete2(data, total, coupon);
    this.reportIre(data.subscription, data.user, data.coupon, data.plan);
    this.reportUetq({amount: total});
    this.surveyOptIn(data);
  }

  private surveyOptIn(data: any) {
    if (isPlatformBrowser(this.platformId)) {
      if ((window as any).gapi) {
        (window as any).gapi.load('surveyoptin', function () {
          (window as any).gapi.surveyoptin.render(
            {
              // REQUIRED FIELDS
              'merchant_id': 129373759,
              'order_id': data.subscription._id,
              'email': data.subscription.user.email,
              'delivery_country': 'US',
              'estimated_delivery_date': format(addDays(data.shipment.printed.cantPrintUntil, 7), 'YYYY-MM-DD'),
            });
        });
      }
    }
  }

  private reportTransactionComplete2(data, total, coupon) {
    if (isPlatformBrowser(this.platformId)) {
      if ('dataLayer' in window) {
        (window as any).dataLayer.push({
          'event': 'transactionComplete',
          'transactionId': this.app_config.production && this.app_config.name === 'production'
            ? data.subscription._id
            : `TEST_${data.subscription._id}`,
          'transactionTotal': total,
          transactionType: data.subscription.plan.type,
          'transactionProducts': [
            {
              'name': data.subscription.plan.title,
              'sku': data.subscription.plan.name,
              'price': total,
              'quantity': 1
            }
          ],
          'customerEmail': data.subscription.user.email,
          'sourceParam': data.subscription.user.utmData && data.subscription.user.utmData.utm_source,
          'coupon': coupon && coupon.code.toLowerCase()
        });
      }
    }
  }

  reportManualAddressFailed() {
    if (isPlatformBrowser(this.platformId) && 'dataLayer' in window) {
      (window as any).dataLayer.push({
        'event': 'manuallyAddressFail',
        'manuallyAddressFail': true,
      });
    }
  }

  reportGiftDone(data: any, total, coupon) {
    this.reportTransactionComplete2(data, total, coupon);
    this.reportUetq({amount: total});
    this.surveyOptIn(data);
    this.reportIre(data.subscription, data.user, data.coupon, data.plan);
  }

  setFullstoryUser(result) {
    if (isPlatformBrowser(this.platformId) && 'FS' in window) {
      console.log('Clear FullStory user.');
      (window as any).FS.identify(result.user._id, {
        displayName: result.user.fullName,
        email: result.user.email
      });
    }
  }

  clearFullstoryUser() {
    if (isPlatformBrowser(this.platformId) && 'FS' in window) {
      console.log('Clear FullStory user.');
      (window as any).FS.identify(false);
    }
  }

  setIdentify(result) { // ???
    if (isPlatformBrowser(this.platformId) && '__insp' in window) {
      console.log('Set __insp.');
      (window as any).__insp.push(['identify', result.user.email]);
    }
  }

  clearIdentify() { // ???
    if (isPlatformBrowser(this.platformId) && '__insp' in window) {
      console.log('Clear __insp.');
      (window as any).__insp.push(['identify', null]);
    }
  }

}
