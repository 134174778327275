import * as tslib_1 from "tslib";
import { ActivatedRoute } from '@angular/router';
import { OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { LocalStorageService } from 'ngx-webstorage';
import { EmailValidator } from '@app/shared/validators/email.validator';
import { LeadsService } from '@app/core/services/leads.service';
import { LeadFormBaseComponent } from '../lead-form-base/lead-form-base.component';
import { ErrorService } from '@app/error';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
var FreeShippingLeadFormComponent = /** @class */ (function (_super) {
    tslib_1.__extends(FreeShippingLeadFormComponent, _super);
    function FreeShippingLeadFormComponent(route, leadService, errorService, localStorage) {
        var _this = _super.call(this) || this;
        _this.route = route;
        _this.leadService = leadService;
        _this.errorService = errorService;
        _this.localStorage = localStorage;
        return _this;
    }
    FreeShippingLeadFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form = new FormGroup({
            email: new FormControl('', EmailValidator.checkEmail)
        });
        this.emailFormField = 'email';
        this.form.get('email').valueChanges
            .pipe(filter(function (data) { return typeof data === 'string' && data.indexOf('@') !== -1; }), debounceTime(1000), distinctUntilChanged())
            .pipe(takeUntil(this.destroyComponent$))
            .subscribe(function (email) {
            _this.testSuggestedEmails(email);
        });
    };
    FreeShippingLeadFormComponent.prototype.submitLeadForm = function () {
        var _this = this;
        var email = this.form.get('email').value;
        if (!email.length || this.isFieldInvalid('email', 'invalidEmail')) {
            return;
        }
        this.createLead(email)
            .then(function () {
            _this.submit.emit({ email: email });
            _this.closeLeadForm();
        })
            .catch(function (error) {
            _this.closeLeadForm();
            var message = "Error trying to create/update Lead.";
            _this.errorService.client.notify(error, {
                beforeSend: function (report) {
                    report.severity = 'warning';
                    report.updateMetaData('extras', {
                        http: true,
                        client: true,
                        message: message,
                        error: error,
                    });
                }
            });
        });
    };
    FreeShippingLeadFormComponent.prototype.createLead = function (email) {
        if (email === void 0) { email = ''; }
        var uuid = this.localStorage.retrieve('uuid');
        var utmData = this.route.snapshot.queryParams;
        this.localStorage.store('customerEmail', email);
        return this.leadService
            .create({ uuid: uuid, email: email, utmData: utmData, leadForm: 'free-shipping' })
            .toPromise()
            .then(function (data) {
            return data;
        });
    };
    FreeShippingLeadFormComponent.prototype.isFieldInvalid = function (field, validation) {
        var control = this.form.get(field);
        return control.hasError(validation) && control.touched;
    };
    return FreeShippingLeadFormComponent;
}(LeadFormBaseComponent));
export { FreeShippingLeadFormComponent };
