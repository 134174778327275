import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// rxjs
import { map } from 'rxjs/operators';

// config
import { APP_CONFIG, AppConfig } from '@app/app.config';
import { environment } from '@env/environment';

@Injectable()
export class BraintreeService {

  constructor(
    private httpClient: HttpClient,
    @Inject(APP_CONFIG) private app_config: AppConfig
  ) { }

  getClientAuthorizationToken(): Promise<string> {
    return Promise.resolve(environment.btClientToken);
  }
}
