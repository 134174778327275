import { Routes } from '@angular/router';
// containers
import * as fromContainers from './containers';
// Guards
import * as fromGuards from '@app/boxes/guards';
import { AuthGuard } from '@app/auth/guards/auth.guard';
import { SubscriptionInfoResolver } from '@app/core/resolvers/subscription-info.resolver';
var ɵ0 = SubscriptionInfoResolver;
var routes = [
    // { path: '', component: fromContainers.HomeComponent },
    { path: 'faq', component: fromContainers.FaqComponent },
    // { path: 'brad', redirectTo: '/gift' },
    // { path: 'bogo', redirectTo: '/gift' },
    {
        path: 'summer',
        component: fromContainers.SummerComponent,
        canActivate: [AuthGuard, fromGuards.AllBoxesGuard],
    },
    { path: 'bogo', component: fromContainers.BogoComponent },
    { path: 'about', component: fromContainers.AboutComponent },
    // { path: 'sales', component: fromContainers.SalesComponent },
    // { path: 'churn', component: fromContainers.ChurnComponent },
    { path: 'claim', component: fromContainers.ClaimComponent },
    { path: 'terms', component: fromContainers.TermsComponent },
    // { path: 'carecards', component: fromContainers.CareCardsComponent },
    // { path: 'inspiration', component: fromContainers.InspirationComponent },
    { path: 'privacy-policy', component: fromContainers.PrivacyPolicyComponent },
    // {
    //   path: 'gift-shipment',
    //   component: fromContainers.GiftComponent,
    //   resolve: {
    //     subscription: SubscriptionInfoResolver
    //   }
    // },
    {
        path: 'gift-done',
        component: fromContainers.GiftDoneComponent,
        resolve: {
            data: ɵ0
        }
    },
];
var PagesRoutingModule = /** @class */ (function () {
    function PagesRoutingModule() {
    }
    return PagesRoutingModule;
}());
export { PagesRoutingModule };
export { ɵ0 };
