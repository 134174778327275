import { CardNumberFormatDirective } from './cc-number-format.directive';
import { CardExpirationFormatDirective } from './cc-expiration-format.directive';
import { CardCvcFormatDirective } from './cc-cvc-format.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { PhoneNumberFormatDirective } from './phone-number-format.directive';
import { FocusDirective } from './focus.directive';
import { BackgroundDinamicDirective } from './bg-dinamic.directive';
import { NonAutocompleteDirective } from '@app/shared/directives/non-autocomplete.directive';
import { DynamicBackgroundDirective } from './dynamic-background.directive';

export const directives = [
  FocusDirective,
  ClickOutsideDirective,
  CardCvcFormatDirective,
  CardNumberFormatDirective,
  PhoneNumberFormatDirective,
  BackgroundDinamicDirective,
  CardExpirationFormatDirective,
  NonAutocompleteDirective,
  DynamicBackgroundDirective,
];

export { CardNumberFormatDirective } from './cc-number-format.directive';
export { CardExpirationFormatDirective } from './cc-expiration-format.directive';
export { CardCvcFormatDirective } from './cc-cvc-format.directive';
export { ClickOutsideDirective } from './click-outside.directive';
export { PhoneNumberFormatDirective } from './phone-number-format.directive';
export { FocusDirective } from './focus.directive';
export { BackgroundDinamicDirective } from './bg-dinamic.directive';
export { DynamicBackgroundDirective } from './dynamic-background.directive';
