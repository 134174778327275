<div class="lead-banner">
  <a class="close" (click)="closeLeadForm()">
    <span class="sr-only">close</span>
  </a>
  <div class="lead-banner-content">
    <h1 class="lead-banner__title text-bolder">#WinItWednesday <span>Succulents!</span> </h1>

    <div class="heart-row">
      <span class="heart-line"></span>
      <img class="heart-icon" srcset="/assets/images/heart.png, /assets/images/heart@2x.png, /assets/images/heart@3x.png" src="/assets/images/heart.png" >
      <span class="heart-line"></span>
    </div>

    <p class="main-text">Enter to win two colorful and oh-so-cute baby succulents.</p>

    <div class="lead-banner__sub-content">

      <form class="lead-banner__form" [formGroup]="form">

        <div class="form-group">
          <input
            class="lead-input form-group__input text-extralight"
            id="nameInput" type="email"
            placeholder="Enter your email"
            formControlName="email"
            required="required"
          />
          <span
            class="lead-error error-text"
            *ngIf="isFieldInvalid('email', 'invalidEmail')"
          >
            Please use a valid email
          </span>

          <span *ngIf="(showSuggestedEmail$ | async)" class="user-suggested-email">
            Do you mean <strong (click)="saveSuggestedEmail()">{{ suggestedEmail$ | async }}</strong>?
          </span>
        </div>

        <div class="form-group">
          <input
            class="lead-input form-group__input text-extralight"
            id="usernameInput" type="text"
            placeholder="Instagram @username"
            formControlName="username"
            required="required"
          />
          <span
            class="lead-error error-text"
            *ngIf="isFieldInvalid('username', 'required')"
          >
            Please enter your Instagram @username.
          </span>
        </div>

        <button
          class="lead-banner__btn btn btn-primary"
          type="button"
          (click)="submitLeadForm()"
        >
          WIN SUCCULENTS
        </button>
      </form>
    </div>

    <span class="flex-span"></span>

    <p class="terms-text">When you enter your e-mail you'll also receive our newsletter with exclusive deals.</p>
  </div>
</div>
