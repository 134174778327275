import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// guards
import { RecoveryPasswordGuard } from '@app/auth/guards/recovery-password.guard';

// components
import { LoginComponent } from './containers/login/login.component';
import { SignupComponent } from './containers/signup/signup.component';
import { RememberPasswordComponent } from './containers/remember-password/remember-password.component';
import { RecoveryPasswordComponent } from './containers/recovery-password/recovery-password.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent,  },
  { path: 'signup', component: SignupComponent },
  {
    path: 'recovery-password',
    component: RecoveryPasswordComponent,
    canActivate: [RecoveryPasswordGuard]
  },
  { path: 'forgot_password', component: RememberPasswordComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
