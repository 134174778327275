<div class="container-fluid">
  <div class="thanks-container">
    <div class="col-12">
      <div class="row">
        <div class="container">
          <div class="col-12 text-center">
            <h1 class="m-0 thanks-title">nice growing!</h1>
          </div>

          <div class="col-12 text-center mb-4">
            <p class="m-0 thanks-subtitle">Thank you for subscribing!</p>
          </div>

          <div class="col-12 text-center mb-5">
            <p class="m-0 thanks-text">
              You just made an unbeleafably good decision, if you ask us. <br>
              We'll get to work selecting your first surprise houseplant. <br>
              In the meantime, check your inbox for a confirmation email!
            </p>
          </div>

          <div class="col-12 text-center">
            <a class="homepage-button" routerLink="/">
              HOMEPAGE
            </a>
          </div>

          <video *ngIf="thanksData?.video" class="video-container" [poster]="thanksData?.video.poster" controls preload="metadata">
            <source [src]="thanksData?.video.srcmp4" type="video/mp4">
            <source [src]="thanksData?.video.srcwebm" type="video/webm">
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  </div>
</div>
