import { LeadFormBaseComponent } from './lead-form-base/lead-form-base.component';
import { FiveOffLeadFormComponent } from './five-off-lead-form/five-off-lead-form.component';
import { FreeShippingLeadFormComponent } from './free-shipping-lead-form/free-shipping-lead-form.component';
import { WinItWednesdayLeadFormComponent } from './win-it-wednesday-lead-form/win-it-wednesday-lead-form.component';

export const leadForms = [
  LeadFormBaseComponent,
  FiveOffLeadFormComponent,
  FreeShippingLeadFormComponent,
  WinItWednesdayLeadFormComponent,
];

export { LeadFormBaseComponent } from './lead-form-base/lead-form-base.component';
export { FiveOffLeadFormComponent } from './five-off-lead-form/five-off-lead-form.component';
export { FreeShippingLeadFormComponent } from './free-shipping-lead-form/free-shipping-lead-form.component';
export { WinItWednesdayLeadFormComponent } from './win-it-wednesday-lead-form/win-it-wednesday-lead-form.component';