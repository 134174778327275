import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// rxjs
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// config
import { APP_CONFIG, AppConfig } from './../../app.config';

// models
import { Shipment } from './../models/shipment.model';

// services
import { AuthService } from './../../auth/services/auth.service';

@Injectable()
export class ShipmentService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    @Inject(APP_CONFIG) private app_config: AppConfig
  ) { }

  getShipments(): Observable<Shipment[]> {
    const userId = this.authService.tokenPayload._id;
    return this.httpClient
      .get(`${this.app_config.api_uri}/users/${userId}/shipments`)
      .pipe(map((result: any) => result.shipments as Shipment[]));
  }
}
