
      <header class="header container">
          <!-- Mobile Header -->
          <div class="header-container row" #headerContainer>
              <div [ngClass]="{'sticked': isSticked }" [ngStyle]="{ 'top': moveFromTop + 'px' }" class="nav-row" *ngIf="!isSales">
                  <a class="logo-button" routerLink="/">
                      hnp
                  </a>
                  <h1 class="header-title header-title--desk">
                      <a routerLink="/"><span class="header-title-bolder">Hey Nice Plants</span></a>
                  </h1>
                  <div class="nav-container" [ngClass]="{ 'isInspiration': isInspiration }" *ngIf="isInspiration">
                      <div class="nav-action">
                          <a routerLink="/checkout">Subscribe</a>
                      </div>
                  </div>
                  <div class="nav-container" *ngIf="!isInspiration">
                      <div
                        class="nav-action"
                        [ngClass]="{ 'logged-in': isAuth }" *ngIf="(isAuthenticated$ | async) as isAuth; else loggedOut">
                          <a #loginButton routerLink="/profile">Account</a>
                          <a [attr.test-id]="'header__logout-button'" routerLink="/" (click)="logout()">Log Out</a>
                          <a *ngIf="userRole === 'admin'" routerLink="/control">Control center</a>
                      </div>
                      <ng-template #loggedOut>
                          <div>
                              <a class="login-button" [attr.test-id]="'header__login-button'" #loginButton routerLink="/login">Log In</a>
                          </div>
                      </ng-template>
                  </div>
              </div>
              <div class="title-row" *ngIf="!isSales">
                  <h1 class="header-title header-title--mobile">
                      <a routerLink="/"><span class="header-title-bolder">Hey Nice Plants</span></a>
                  </h1>
              </div>
          </div>
      </header>

      <div class="nav-row" style="pointer-events: none; position: initial;"></div>
  