<div class="page-container">
  <section class="privacy-container container">
    <h1 class="section__title privacy__title center-text">{{ termsData?.pageTitle }}</h1>
    <div class="privacy__content">
      <div *ngFor="let section of termsData?.terms_sections" class="privacy__section">
        <h4 *ngIf="section.title" class="privacy__section__title">{{ section.title }}</h4>
        <div [innerHTML]="section.text | safeHtml"></div>
      </div>
    </div>
  </section>
</div>
