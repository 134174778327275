import * as tslib_1 from "tslib";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import * as subscriptionsActions from '@app/store/actions/subscription.action';
import * as addressesActions from './../actions/address.actions';
import * as shipmentsActions from './../actions/shipment.action';
// services
import { SubscriptionService } from '@app/core/services/subscription.service';
// actions
import { OpenNotificationBarAction } from '@app/store/actions/notification-bar.actions';
import { ModalService } from '@app/core/services';
var SubscriptionEffects = /** @class */ (function () {
    function SubscriptionEffects(subscriptionService, modalService, actions$, store) {
        var _this = this;
        this.subscriptionService = subscriptionService;
        this.modalService = modalService;
        this.actions$ = actions$;
        this.store = store;
        this.load$ = this.actions$
            .pipe(ofType(subscriptionsActions.LOAD_SUBSCRIPTIONS), switchMap(function () {
            return _this.subscriptionService.getSubscriptions()
                .pipe(map(function (subscriptions) { return _this.store.dispatch(new subscriptionsActions.LoadSubscriptionsCompleteAction(subscriptions)); }), catchError(function (reason) {
                _this.store.dispatch(new subscriptionsActions.LoadSubscriptionsFailAction(reason.errors));
                return of(new subscriptionsActions.LoadSubscriptionsFailAction(reason.errors));
            }));
        }));
        this.loadbyId$ = this.actions$.pipe(ofType(subscriptionsActions.LOAD_SUBSCRIPTION), map(function (action) { return action.payload; }), switchMap(function (_a) {
            var id = _a.id;
            return _this.subscriptionService.getSubscription(id).pipe(map(function (subscription) {
                return _this.store.dispatch(new subscriptionsActions.LoadSubscriptionCompleteAction(subscription));
            }), catchError(function (reason) {
                _this.store.dispatch(new subscriptionsActions.LoadSubscriptionFailAction(reason.error));
                return of(new subscriptionsActions.LoadSubscriptionFailAction(reason.error));
            }));
        }));
        this.update$ = this.actions$
            .pipe(ofType(subscriptionsActions.UPDATE_SUBSCRIPTION), map(function (action) { return action.payload; }), switchMap(function (_a) {
            var subscriptionId = _a.subscriptionId, recipient = _a.recipient;
            return _this.subscriptionService.updateSubscription({ _id: subscriptionId, recipient: recipient })
                .pipe(map(function (subscription) { return _this.store.dispatch(new subscriptionsActions.UpdateSubscriptionCompleteAction({
                id: subscription._id,
                changes: tslib_1.__assign({}, subscription, { isLoading: false })
            })); }), catchError(function (reason) {
                _this.store.dispatch(new subscriptionsActions.LoadSubscriptionsFailAction(reason.errors));
                return of(new subscriptionsActions.LoadSubscriptionsFailAction(reason.errors));
            }));
        }));
        this.skipIt$ = this.actions$
            .pipe(ofType(subscriptionsActions.SKIP_IT_SUBSCRIPTION), map(function (action) { return action.payload; }), switchMap(function (data) {
            return _this.subscriptionService.skipMonth(data.subscriptionId, data.skipValue)
                .pipe(map(function (updatedSubscription) {
                _this.store.dispatch(new subscriptionsActions.UpdateSubscriptionCompleteAction({
                    id: updatedSubscription._id,
                    changes: tslib_1.__assign({}, updatedSubscription, { isLoading: false })
                }));
            }), catchError(function (reason) {
                _this.store.dispatch(new subscriptionsActions.LoadSubscriptionsFailAction(reason.error));
                return of(new subscriptionsActions.LoadSubscriptionsFailAction(reason.error));
            }));
        }));
        this.unSkip$ = this.actions$
            .pipe(ofType(subscriptionsActions.UN_SKIP_SUBSCRIPTION), map(function (action) { return action.payload; }), switchMap(function (_a) {
            var subscriptionId = _a.subscriptionId;
            return _this.subscriptionService.unSkipMonth(subscriptionId)
                .pipe(map(function (updatedSubscription) {
                _this.store.dispatch(new subscriptionsActions.UpdateSubscriptionCompleteAction({
                    id: updatedSubscription._id,
                    changes: tslib_1.__assign({}, updatedSubscription, { isLoading: false })
                }));
            }), catchError(function (reason) {
                _this.store.dispatch(new subscriptionsActions.LoadSubscriptionsFailAction(reason.error));
                return of(new subscriptionsActions.LoadSubscriptionsFailAction(reason.error));
            }));
        }));
        this.reActivate$ = this.actions$
            .pipe(ofType(subscriptionsActions.RE_ACTIVATE_SUBSCRIPTION), map(function (action) { return action.payload; }), switchMap(function (data) {
            return _this.subscriptionService.reActivate(data.nextBillingDate, data.subscriptionId)
                .pipe(map(function (updatedSubscription) {
                _this.store.dispatch(new subscriptionsActions.UpdateSubscriptionCompleteAction({
                    id: updatedSubscription._id,
                    changes: tslib_1.__assign({}, updatedSubscription, { isLoading: false })
                }));
            }), catchError(function (reason) {
                _this.store.dispatch(new subscriptionsActions.LoadSubscriptionsFailAction(reason.error));
                return of(new subscriptionsActions.LoadSubscriptionsFailAction(reason.error));
            }));
        }));
        this.turnSubscriptionOff$ = this.actions$.pipe(ofType(subscriptionsActions.TURN_OFF_SUBSCRIPTION), map(function (action) { return action.payload; }), switchMap(function (_a) {
            var subscriptionId = _a.subscriptionId, turnedOffReasons = _a.turnedOffReasons, refundShipment = _a.refundShipment;
            return _this.subscriptionService
                .turnOffAutoRenew({ subscriptionId: subscriptionId, turnedOffReasons: turnedOffReasons, refundShipment: refundShipment })
                .pipe(map(function (_a) {
                var subscription = _a.subscription, shipment = _a.shipment;
                _this.store.dispatch(new OpenNotificationBarAction({
                    title: '',
                    message: "Your subscription is now cancelled",
                    mode: 'success',
                    type: 'notification',
                }));
                _this.store.dispatch(new subscriptionsActions.UpdateSubscriptionCompleteAction({
                    id: subscription._id,
                    changes: tslib_1.__assign({}, subscription, { isLoading: false })
                }));
                if (shipment) {
                    _this.store.dispatch(new shipmentsActions.UpdateShipmentCompleteAction(shipment));
                }
                _this.modalService.close("renew-modal");
            }), catchError(function (reason) {
                _this.store.dispatch(new subscriptionsActions.TurnOffSubscriptionFailAction(reason.error));
                return of(new subscriptionsActions.TurnOffSubscriptionFailAction(reason.error));
            }));
        }));
        this.turnSubscriptionOn$ = this.actions$.pipe(ofType(subscriptionsActions.TURN_ON_SUBSCRIPTION), map(function (action) { return action.payload; }), switchMap(function (_a) {
            var subscriptionId = _a.subscriptionId, nextBillingDate = _a.nextBillingDate;
            return _this.subscriptionService
                .turnOnAutoRenew({ subscriptionId: subscriptionId, nextBillingDate: nextBillingDate })
                .pipe(map(function (subscription) {
                _this.store.dispatch(new OpenNotificationBarAction({
                    title: '',
                    message: "Your subscription's is now active",
                    mode: 'success',
                    type: 'notification',
                }));
                _this.store.dispatch(new subscriptionsActions.UpdateSubscriptionCompleteAction({
                    id: subscription._id,
                    changes: tslib_1.__assign({}, subscription, { isLoading: false })
                }));
                _this.modalService.close("renew-modal");
            }), catchError(function (reason) {
                _this.modalService.close("renew-modal");
                _this.store.dispatch(new subscriptionsActions.TurnOnSubscriptionFailAction(reason.error));
                _this.store.dispatch(new OpenNotificationBarAction({
                    title: '',
                    message: "Can not turn on the subscription, try again.",
                    mode: 'error',
                    type: 'notification',
                }));
                return of(new subscriptionsActions.TurnOnSubscriptionFailAction(reason.error));
            }));
        }));
        this.giftIt$ = this.actions$
            .pipe(ofType(subscriptionsActions.GIFT_IT_SUBSCRIPTION), map(function (action) { return action.payload; }), switchMap(function (_a) {
            var subscriptionId = _a.subscriptionId, gift = _a.gift;
            return _this.subscriptionService.giftShipment({
                subscriptionId: subscriptionId,
                giftIt: gift,
                useLastShipmentWithoutShip: false,
            })
                .pipe(map(function (_a) {
                var updatedSubscription = _a.subscription, address = _a.address, shipment = _a.shipment;
                _this.modalService.close("gift-it-modal");
                _this.store.dispatch(new subscriptionsActions.UpdateSubscriptionCompleteAction({
                    id: updatedSubscription._id,
                    changes: tslib_1.__assign({}, updatedSubscription, { isLoading: false })
                }));
                if (shipment) {
                    _this.store.dispatch(new shipmentsActions.UpdateShipmentCompleteAction(shipment));
                }
                _this.store.dispatch(new addressesActions.AddAddressesCompleteAction(address));
            }), catchError(function (reason) {
                _this.store.dispatch(new OpenNotificationBarAction({
                    title: '',
                    message: reason.error.message,
                    mode: 'error',
                    type: 'notification',
                }));
                _this.store.dispatch(new subscriptionsActions.UpdateSubscriptionFailAction(reason.error));
                return of(new subscriptionsActions.UpdateSubscriptionFailAction(reason.error));
            }));
        }));
        this.cancelGiftIt$ = this.actions$
            .pipe(ofType(subscriptionsActions.CANCEL_GIFT_IT), map(function (action) { return action.payload; }), switchMap(function (_a) {
            var giftId = _a.giftId, subscriptionId = _a.subscriptionId;
            return _this.subscriptionService.cancelGiftShipment(subscriptionId, giftId)
                .pipe(map(function (_a) {
                var subscription = _a.subscription, shipment = _a.shipment;
                _this.store.dispatch(new subscriptionsActions.UpdateSubscriptionCompleteAction({
                    id: subscription._id,
                    changes: tslib_1.__assign({}, subscription, { isLoading: false })
                }));
                if (shipment) {
                    _this.store.dispatch(new shipmentsActions.UpdateShipmentCompleteAction(shipment));
                }
            }), catchError(function (reason) {
                _this.store.dispatch(new subscriptionsActions.UpdateSubscriptionFailAction(reason.error));
                return of(new subscriptionsActions.UpdateSubscriptionFailAction(reason.error));
            }));
        }));
        this.cancelGiftItExtraBox$ = this.actions$
            .pipe(ofType(subscriptionsActions.CANCEL_GIFT_IT_EXTRA_BOX), map(function (action) { return action.payload; }), switchMap(function (_a) {
            var giftId = _a.giftId, subscriptionId = _a.subscriptionId;
            return _this.subscriptionService.cancelGiftExtraBoxShipment(subscriptionId, giftId)
                .pipe(map(function (subscription) {
                _this.store.dispatch(new subscriptionsActions.UpdateSubscriptionCompleteAction({
                    id: subscription._id,
                    changes: tslib_1.__assign({}, subscription, { isLoading: false })
                }));
                _this.store.dispatch(new OpenNotificationBarAction({
                    title: '',
                    message: "Next shipment successfully canceled! Your gift box is still scheduled.",
                    mode: 'success',
                    type: 'notification',
                }));
            }), catchError(function (reason) {
                _this.store.dispatch(new subscriptionsActions.UpdateSubscriptionFailAction(reason.error));
                return of(new subscriptionsActions.UpdateSubscriptionFailAction(reason.error));
            }));
        }));
        this.cancel$ = this.actions$
            .pipe(ofType(subscriptionsActions.CANCEL_SUBSCRIPTION), map(function (action) { return action.payload; }), switchMap(function (_a) {
            var subscriptionId = _a.subscriptionId, reasons = _a.reasons;
            return _this.subscriptionService.cancelSubscription(subscriptionId, reasons)
                .pipe(map(function (updatedSubscription) {
                _this.store.dispatch(new subscriptionsActions.UpdateSubscriptionCompleteAction({
                    id: updatedSubscription._id,
                    changes: tslib_1.__assign({}, updatedSubscription, { isLoading: false })
                }));
                _this.modalService.open("cancel-subscription-success");
            }), catchError(function (reason) {
                _this.store.dispatch(new subscriptionsActions.UpdateSubscriptionFailAction(reason.error));
                return of(new subscriptionsActions.UpdateSubscriptionFailAction(reason.error));
            }));
        }));
        this.updateAddress$ = this.actions$
            .pipe(ofType(subscriptionsActions.UPDATE_SUBSCRIPTION_ADDRESS), map(function (action) { return action.payload; }), switchMap(function (_a) {
            var subscriptionId = _a.subscriptionId, address = _a.address;
            return _this.subscriptionService.updateSubscriptionAddress({ subscriptionId: subscriptionId, address: address })
                .pipe(tap(function (data) {
                // Update subscription.
                _this.store.dispatch(new subscriptionsActions.UpdateSubscriptionAddressCompleteAction({
                    id: data.subscription._id,
                    changes: tslib_1.__assign({}, data.subscription, { isLoading: false })
                }));
                // Add new address to the store.
                _this.store.dispatch(new addressesActions.AddAddressesCompleteAction(data.address));
            }), catchError(function (reason) {
                _this.store.dispatch(new subscriptionsActions.UpdateSubscriptionAddressFailAction(reason.error));
                return of(new subscriptionsActions.UpdateSubscriptionAddressFailAction(reason.error));
            }));
        }));
        this.updateNBD$ = this.actions$
            .pipe(ofType(subscriptionsActions.UPDATE_SUBSCRIPTION_NBD), map(function (action) { return action.payload; }), switchMap(function (_a) {
            var subscriptionId = _a.subscriptionId, newBillingDate = _a.newBillingDate;
            return _this.subscriptionService.updateSubscriptionNBD({ subscriptionId: subscriptionId, newBillingDate: newBillingDate })
                .pipe(tap(function (data) {
                _this.store.dispatch(new subscriptionsActions.UpdateSubscriptionNBDCompleteAction({
                    id: data.subscription._id,
                    changes: tslib_1.__assign({}, data.subscription, { isLoading: false })
                }));
                _this.modalService.close("update-nbd-modal");
            }), catchError(function (reason) {
                _this.store.dispatch(new subscriptionsActions.UpdateSubscriptionNBDFailAction(reason.error));
                return of(new subscriptionsActions.UpdateSubscriptionNBDFailAction(reason.error));
            }));
        }));
        this.reactivateSubscriptionFreeShipment$ = this.actions$
            .pipe(ofType(subscriptionsActions.REACTIVATE_SUBSCRIPTION_FREE_SHIPMENT), map(function (action) { return action.payload; }), switchMap(function (_a) {
            var subscriptionId = _a.subscriptionId;
            return _this.subscriptionService.reactivateSubscriptionWinBack(subscriptionId)
                .pipe(tap(function (_a) {
                var subscription = _a.subscription, shipment = _a.shipment;
                _this.modalService.close('reactivate-winback-email');
                _this.store.dispatch(new OpenNotificationBarAction({
                    title: '',
                    message: "You're all set! Your subscription has been reactivated, and your succulent box has been processed with free shipping!",
                    mode: 'success',
                    type: 'notification',
                }));
                _this.store.dispatch(new subscriptionsActions.UpdateSubscriptionCompleteAction({
                    id: subscription._id,
                    changes: tslib_1.__assign({}, subscription, { isLoading: false })
                }));
                _this.store.dispatch(new shipmentsActions.AddShipmentCompleteAction(shipment));
            }), catchError(function (reason) {
                _this.store.dispatch(new subscriptionsActions.UpdateSubscriptionNBDFailAction(reason.error));
                return of(new subscriptionsActions.UpdateSubscriptionNBDFailAction(reason.error));
            }));
        }));
        this.unselectBox$ = this.actions$
            .pipe(ofType(subscriptionsActions.UNSELECT_BOX), map(function (action) { return action.payload; }), switchMap(function (_a) {
            var subscriptionId = _a.subscriptionId, boxId = _a.boxId;
            console.log('UNSELECT!!!');
            return _this.subscriptionService.unselectSubscriptionBox(subscriptionId, boxId)
                .pipe(tap(function (_a) {
                var subscription = _a.subscription, shipment = _a.shipment;
                _this.store.dispatch(new OpenNotificationBarAction({
                    title: '',
                    message: "You're all set! You will receive the regular box now.",
                    mode: 'success',
                    type: 'notification',
                }));
            }), catchError(function (reason) {
                console.log(reason);
                return of(false);
            }));
        }));
    }
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], SubscriptionEffects.prototype, "load$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], SubscriptionEffects.prototype, "loadbyId$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], SubscriptionEffects.prototype, "update$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], SubscriptionEffects.prototype, "skipIt$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], SubscriptionEffects.prototype, "unSkip$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], SubscriptionEffects.prototype, "reActivate$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], SubscriptionEffects.prototype, "turnSubscriptionOff$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], SubscriptionEffects.prototype, "turnSubscriptionOn$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], SubscriptionEffects.prototype, "giftIt$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], SubscriptionEffects.prototype, "cancelGiftIt$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], SubscriptionEffects.prototype, "cancelGiftItExtraBox$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], SubscriptionEffects.prototype, "cancel$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], SubscriptionEffects.prototype, "updateAddress$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], SubscriptionEffects.prototype, "updateNBD$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], SubscriptionEffects.prototype, "reactivateSubscriptionFreeShipment$", void 0);
    tslib_1.__decorate([
        Effect({ dispatch: false }),
        tslib_1.__metadata("design:type", Object)
    ], SubscriptionEffects.prototype, "unselectBox$", void 0);
    return SubscriptionEffects;
}());
export { SubscriptionEffects };
