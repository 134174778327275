import { ComponentRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { LocalStorageService } from 'ngx-webstorage';
import { forkJoin, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { FiveOffLeadFormComponent, FreeShippingLeadFormComponent, WinItWednesdayLeadFormComponent, } from '@app/shared/components/lead-forms';
import { paramsMap } from '@app/core/mappers/params-map.mapper';
import * as fromRoot from '@app/store/reducers';
export var LEAD_FORM_TYPE;
(function (LEAD_FORM_TYPE) {
    LEAD_FORM_TYPE["FIVE_OFF"] = "FIVE_OFF";
    LEAD_FORM_TYPE["FREE_SHIPPING"] = "FREE_SHIPPING";
    LEAD_FORM_TYPE["WIN_IT_WEDNESDAY"] = "WIN_IT_WEDNESDAY";
})(LEAD_FORM_TYPE || (LEAD_FORM_TYPE = {}));
var LeadFormComponentService = /** @class */ (function () {
    function LeadFormComponentService(overlay, platformId, router, route, localStorage, store) {
        var _this = this;
        this.overlay = overlay;
        this.platformId = platformId;
        this.router = router;
        this.route = route;
        this.localStorage = localStorage;
        this.store = store;
        this.componentDestroyed$ = new Subject();
        this.routerState$ = this.store.pipe(takeUntil(this.componentDestroyed$), select(fromRoot.getRouterState));
        this.canShowLeadForm$ = forkJoin([
            this.routerState$.pipe(take(1)),
            this.route.queryParams.pipe(map(paramsMap), take(1)),
        ])
            .pipe(map(function (_a) {
            var routerState = _a[0], queryParams = _a[1];
            return queryParams.vn !== 'np'
                && _this.localStorage.retrieve('isBannerActive') !== false
                && routerState.navigationId === 1;
        }));
        this.canShowLeadFormWithoutLS$ = forkJoin([
            this.routerState$.pipe(take(1)),
            this.route.queryParams.pipe(map(paramsMap), take(1)),
        ])
            .pipe(map(function (_a) {
            var routerState = _a[0], queryParams = _a[1];
            return queryParams.vn !== 'np' && routerState.navigationId === 1;
        }));
    }
    LeadFormComponentService.prototype.ngOnDestroy = function () {
        this.componentDestroyed$.next();
    };
    LeadFormComponentService.prototype.open = function (leadFormType) {
        if (!isPlatformBrowser(this.platformId)) {
            console.error('Not in the browser.');
            return null;
        }
        var overlayRef = this.overlay.create({
            hasBackdrop: true,
            backdropClass: 'dark-backdrop',
            panelClass: 'modal-dialog-panel',
            scrollStrategy: this.overlay.scrollStrategies.noop(),
            positionStrategy: this.overlay.position()
                .global()
                .centerHorizontally()
                .centerVertically(),
        });
        var leadFormPortal;
        if (leadFormType === LEAD_FORM_TYPE.FIVE_OFF) {
            leadFormPortal = new ComponentPortal(FiveOffLeadFormComponent);
        }
        else if (leadFormType === LEAD_FORM_TYPE.FREE_SHIPPING) {
            leadFormPortal = new ComponentPortal(FreeShippingLeadFormComponent);
        }
        else if (leadFormType === LEAD_FORM_TYPE.WIN_IT_WEDNESDAY) {
            leadFormPortal = new ComponentPortal(WinItWednesdayLeadFormComponent);
        }
        var containerRef = overlayRef.attach(leadFormPortal);
        containerRef.instance.close.subscribe(function () { return overlayRef.dispose(); });
        overlayRef.backdropClick().subscribe(function () { return overlayRef.dispose(); });
        return containerRef.instance;
    };
    return LeadFormComponentService;
}());
export { LeadFormComponentService };
